import React, { useEffect, useState } from 'react';
import { Box, Button, Input, LinearProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import useAnexos from './../../hooks/useAnexos';
import { StackRight } from '../../components/stack';
import { ContainerPages } from '../../components/container';
import { TextFieldDefault } from '../../components/textfield';
import { TextConteudo } from '../../components/typography';

export default function Versionamento() {
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [upload, setUplod] = useState(true);
    const { postCopia, progress, statusBar } = useAnexos();
    const [versao, setVersao] = useState('4.1.0.000');

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
        setUplod(true);
        if (selectedFile) {
            postCopia(versao, selectedFile)
        }
        setUplod(false);
    };

    useEffect(() => {
        setUplod(true);
    }, [progress === 100])

    useEffect(() => {
        setSelectedFile(undefined)
    }, [statusBar === false])

    return (
        <ContainerPages title='Versionamento' btnVoltar={true} activeAuxBar={true}>
            <TextFieldDefault label='Versão' value={versao} onChange={(e) => setVersao(e.target.value)} />
            {!upload ? <><LinearProgress variant="determinate" value={progress} /><TextConteudo text={progress} />%</> :
                <Box pl={0}>
                    {!selectedFile && (
                        <div style={{ textAlign: 'start' }}>
                            <Input
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                id="upload-file"
                            />
                            <label htmlFor="upload-file">
                                <Button
                                    variant="contained"
                                    component="span"
                                    size='small'
                                    startIcon={<CloudUploadIcon />}
                                    color='blue'
                                >
                                    Selecione o arquivo
                                </Button>
                            </label>{" "}
                        </div>
                    )}
                    {selectedFile ?
                        <>
                            <TextFieldDefault label='Arquivo' value={selectedFile.name} />
                            <StackRight >
                                <Button
                                    variant="contained"
                                    component="span"
                                    size='small'
                                    startIcon={<CloudUploadIcon />}
                                    color='blue'
                                    onClick={handleUpload}
                                >
                                    Enviar
                                </Button>
                                <Button
                                    variant="contained"
                                    component="span"
                                    size='small'
                                    startIcon={<DeleteIcon />}
                                    color='red'
                                    onClick={() => setSelectedFile(undefined)}
                                >
                                    Cancelar
                                </Button>
                            </StackRight>
                        </>
                        : ''}
                </Box>
            }
        </ContainerPages>
    );
};
