import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useRastreios from '../../hooks/useRastreios';
import { Box } from '@mui/material';
import { TextFieldButton2 } from '../../components/textfield';
import { ContainerPages } from '../../components/container';
import { ButtonToolTip } from '../../components/button';
import { Add, Delete, EditIcon } from '../../components/icons';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import { TitleGrupos, TitleSmall } from '../../components/typography';

export default function Rastreios() {
    const { getRastreios, postRastreio, removeRastreio, rastreios, loading, putRastreio } = useRastreios();
    const [master, setMaster] = React.useState({ codigo: 0, descricao: 'Rastreios', master: 0 });
    const [item, setItem] = React.useState({ codigo: 0, descricao: '', master: 0 });
    const [at, setAt] = React.useState(false);
    const [showForm, setShowForm] = React.useState(false);

    React.useEffect(() => {
        setShowForm(false);
        getRastreios();
        setTimeout(() => {
            setAt(false);
            let descricaoInput = document.querySelector('input[name="descricao"]');
            if (descricaoInput)
                descricaoInput.focus();
        }, 1000);
    }, [at === true])

    function ListarItens(item) {
        let child = rastreios.filter(it => it.master === item.codigo);
        return <TreeItem key={item.codigo} nodeId={item.codigo + ''} label={<TitleGrupos title={item.descricao} />} onClick={() => setMaster(item)}>
            {child.length === 0 ? "" : child.map(ListarItens)}
        </TreeItem>
    }

    function newItem(codigoMaster) {
        setItem({ codigo: 0, descricao: '', master: codigoMaster })
        setShowForm(true);
    }

    function addItem() {
        if (item.codigo === 0) {
            postRastreio({ master: master.codigo, descricao: item.descricao });
        } else {
            putRastreio(item);
        }
        setItem({ codigo: 0, descricao: '', master: 0 });
        setAt(true);
    }

    function removeItem() {
        removeRastreio(master.codigo)
        setAt(true);
    }

    function cancelarItem() {
        setItem('')
        setAt(true);
    }

    const handleChange = (event) => {
        let value = event.target.value;
        setItem((prevalue) => {
            return {
                ...prevalue,
                'descricao': value
            }
        })
    }
    function editarItem() {
        setItem(master);
        setShowForm(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        addItem();
    }


    return (
        <ContainerPages activeAuxBar={true} title='Rastreios' ativarLogo={true} overflow={true}
            btnAux={<>
                <ButtonToolTip title='Novo item' color='green' icon={<Add />} onClick={() => newItem(master.codigo)} />
                <ButtonToolTip title='Editar item' color='blue' icon={<EditIcon />} onClick={() => editarItem()} />
                <ButtonToolTip title='Remover Item' color='red' icon={<Delete />} onClick={() => removeItem()} />
            </>}
        >
            <Box p={1}>
                {showForm ?
                    <form onSubmit={handleSubmit}>
                        <TitleSmall title='Item Rastreio' />
                        <TextFieldButton2 name='descricao' onChange={handleChange} onClickCancel={() => cancelarItem()} value={item.descricao} onClickCheck={() => addItem()} />
                    </form>
                    : ''}
                <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMoreIcon color='iconColor' />}
                    defaultExpandIcon={<ChevronRightIcon color='iconColor' />}
                    defaultExpanded={['0']}
                >
                    <TreeItem nodeId={'0'} key={0} label={<TitleGrupos title='Rastreios' />} onClick={() => setMaster({ codigo: 0, descricao: 'Rastreios', master: 0 })} >
                        {loading ? '' : rastreios.filter(it => it.master === 0).map(ListarItens)}
                    </TreeItem>
                </TreeView>
            </Box>
        </ContainerPages >
    );
}