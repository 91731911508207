import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { AZIcon, IconMenu, IconMessage, ListIcon, ModeMail, MoonIcon, MoreV, Power, Settings, SunIcon, IconMark } from '../icons';
import { IconButton } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { verificarRota } from '../../utils/geral';
import { getLayout, getTheme, setLayout, setTheme } from '../../utils/theme';
import { Link } from 'react-router-dom';
import { StackLeft } from '../stack';
import { Title } from '../typography';
import { getUser, permRender } from '../../utils/user';
import useConfig from '../../hooks/useConfig';
import { GeralContext } from '../../context';

export function MenuAppBar() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (verificarRota('externo') ? '' :
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreV />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <SubMenuConfig />
                <MenuItem >
                    <Link to={'/login'} style={{ textDecoration: 'none', width: '100%', color: 'inherit' }} onClick={() => sessionStorage.clear()}>
                        <StackLeft>
                            <Power /><Title title='Sair' />
                        </StackLeft>
                    </Link>
                </MenuItem>
            </Menu >
        </div >
    );
}


function SubMenuConfig() {
    const { setIgFilas } = useConfig();
    const { toggleDesativarPesquisa, toggleOrdemSac, ordemSac, expandedSubGrp, toggleExpandedSubGrp, igFila } = React.useContext(GeralContext);
    const [fila, setFila] = React.useState(igFila)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        setFila(igFila);
    }, [igFila])

    return <div>
        <MenuItem
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{ color: 'inherit' }}
        >
            <StackLeft >
                <Settings /><Title title='Config.' />
            </StackLeft>
        </MenuItem>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            style={{ marginLeft: '-10px' }}
        >
            {
                getTheme() === 'dark' ?
                    <MenuItem style={{ color: 'inherit' }} onClick={() => setTheme('light')}>
                        <StackLeft>
                            <SunIcon /><Title title='Tema Light' />
                        </StackLeft>
                    </MenuItem> :
                    <MenuItem style={{ color: 'inherit' }} onClick={() => setTheme('dark')}>
                        <StackLeft>
                            <MoonIcon /><Title title='Tema Dark' />
                        </StackLeft>
                    </MenuItem>
            }
            {
                getLayout() === 'email' && window.innerWidth > 768 ?
                    <MenuItem style={{ color: 'inherit' }} onClick={() => setLayout('padrao')}>
                        <StackLeft>
                            <ListIcon /><Title title='Modo Padrão' />
                        </StackLeft>
                    </MenuItem>
                    :
                    <MenuItem style={{ color: 'inherit' }} onClick={() => setLayout('email')}>
                        <StackLeft>
                            <ModeMail /><Title title='Modo Email' />
                        </StackLeft>
                    </MenuItem>
            }

            {
                ordemSac === 'padrao' ?
                    <MenuItem style={{ color: 'inherit' }} onClick={handleClose}>
                        <StackLeft onClick={() => toggleOrdemSac('az')}>
                            <AZIcon /><Title title='Ordem Alfabética' />
                        </StackLeft>
                    </MenuItem>
                    :
                    <MenuItem style={{ color: 'inherit' }} onClick={handleClose}>
                        <StackLeft onClick={() => toggleOrdemSac('padrao')}>
                            <IconMenu /><Title title='Ordem Padrão' />
                        </StackLeft>
                    </MenuItem>
            }

            {getUser().nome === 'JHENNIFER' || getUser().nome === 'FILIPE' || getUser().nome === 'GABRIEL' ?
                <MenuItem style={{ color: 'inherit' }} onClick={handleClose}>
                    <Link to={'/mensagem'} style={{ textDecoration: 'none', width: '100%', color: 'inherit' }} onClick={toggleDesativarPesquisa}>
                        <StackLeft>
                            <IconMessage /><Title title='Cad. Mensagem' />
                        </StackLeft>
                    </Link>
                </MenuItem>
                : ''}

            {getUser().nome === 'JHENNIFER' || getUser().nome === 'FILIPE' || getUser().nome === 'GABRIEL' ?
                <MenuItem style={{ color: 'inherit' }} onClick={handleClose}>
                    <Link to={'/mudartags'} style={{ textDecoration: 'none', width: '100%', color: 'inherit' }} onClick={toggleDesativarPesquisa}>
                        <StackLeft>
                            <IconMark /><Title title='Mudar Tags' />
                        </StackLeft>
                    </Link>
                </MenuItem>
                : ''}
            {/* {permRender('CR',
                <MenuItem onClick={() => setIgFilas(!igFila)}>
                    <StackLeft>
                        {igFila ?
                            <CheckBoxIcon />
                            :
                            <CheckBoxOutlineBlankIcon />
                        }
                        <Title title='Ignorar Fila' />
                    </StackLeft>
                </MenuItem>
            )} */}
        </Menu >
    </div >
}