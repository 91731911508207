import React, { useEffect, useState } from "react";
import { ContainerPages } from "../../components/container";
import { Box } from "@mui/material";
import { Delete, EditIcon, Add, LinkOn, LinkOff } from "../../components/icons";
import { ButtonToolTip } from "../../components/button";
import { encontrarMaiorId } from "../../utils/jsonFunctions";
import { TitleGrupos, TitleSmall } from "../../components/typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeItem, TreeView } from "@mui/x-tree-view";
import useFaq from "../../hooks/useFaq";
import { TextFieldButton2 } from "../../components/textfield";
import { FAQDialogRef } from "../../components/dialog";
import { BoxFaq } from "../../components/box";


export default function FAQConfig() {
    const { postFAQMenu, faqMenu, getFAQMenu, loadingFAQ, listFaqApro, getFaqAprovados } = useFaq();
    const [master, setMaster] = React.useState({ codigo: 0, descricao: 'FAQ', master: 0, idFAQ: 0 });
    const [item, setItem] = React.useState({ codigo: 0, descricao: '', master: 0 });
    const [at, setAt] = React.useState(false);
    const [showDialogRef, setShowDialogRef] = useState(false);
    const [showForm, setShowForm] = React.useState(false);

    useEffect(() => {
        getFAQMenu();
        setAt(false)
    }, [at === true, loadingFAQ])

    useEffect(() => {
        getFaqAprovados();
    }, [showDialogRef === true])

    function ListarItens(item) {
        let child = faqMenu.filter(it => it.master === item.codigo);
        return <TreeItem key={item.codigo} nodeId={item.codigo + ''} label={<TitleGrupos title={`${item.descricao} ${item.idFAQ > 0 ? ' - ' + item.idFAQ : ''}`} />} onClick={() => setMaster(item)}>
            {child.length === 0 ? "" : child.map(ListarItens)}
        </TreeItem>
    }

    function newItem(codigoMaster) {
        setItem({ codigo: 0, descricao: '', master: codigoMaster, idFAQ: 0 })
        setShowForm(true);
    }

    function addItem() {
        if (item.codigo === 0) {
            let id = encontrarMaiorId(faqMenu) + 1;
            faqMenu.push({ codigo: id, descricao: item.descricao, master: master.codigo, tipo: master.tipo, idFAQ: 0 })
            postFAQMenu(faqMenu);
        } else {
            for (let i = 0; i < faqMenu.length; i++) {
                if (faqMenu[i].codigo === item.codigo)
                    faqMenu[i].descricao = item.descricao;
            }
            postFAQMenu(faqMenu);
        }

        setItem({ codigo: 0, descricao: '', master: 0, tipo: '', idFAQ: 0 });
        setTimeout(() => {
            setAt(true)
        }, 2000);
    }

    function removeItem(codigo) {
        let novofaqMenu = faqMenu.filter(item => item.codigo !== codigo);
        postFAQMenu(novofaqMenu);
        setTimeout(() => {
            setAt(true)
        }, 1000);
    }

    function cancelarItem() {
        setItem({ codigo: 0, descricao: '', master: 0, tipo: '', idFAQ: 0 });
        setShowForm(false)
    }

    const handleChange = (event) => {
        let value = event.target.value;
        setItem((prevalue) => {
            return {
                ...prevalue,
                'descricao': value
            }
        })
    }
    function editarItem() {
        setItem(master);
        setShowForm(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        addItem();
    }
    function vincularFaq(item) {
        for (let i = 0; i < faqMenu.length; i++) {
            if (faqMenu[i].codigo === master.codigo) {
                if (faqMenu[i].idFAQ === 0)
                    faqMenu[i].idFAQ = item.codigo;
                else
                    faqMenu[i].idFAQ = 0;
            }
        }
    
        postFAQMenu(faqMenu);
        setShowDialogRef(false);
        setTimeout(() => {
            setAt(true)
        }, 1000);
    }
 
    function ListarFaq(item) {
        return <BoxFaq key={item.codigo} onClick={() => vincularFaq(item)} faq={item} />
    }

    const handleClose = () => {
        setShowDialogRef(false);
    };

    return (
        <ContainerPages activeAuxBar={true} title='FAQ - Configurar Menu' ativarLogo={true} overflow={true} toVoltar='/faq'
            btnAux={<>
                <ButtonToolTip title='Novo item' color='green' icon={<Add />} onClick={() => newItem(master.codigo)} />            
                {master.idFAQ === 0 ? 
                    <ButtonToolTip title='Referênciar FAQ' icon={<LinkOn />} onClick={() => setShowDialogRef(true)} /> 
                    :
                    <ButtonToolTip title='Referênciar FAQ' icon={<LinkOff />} onClick={() => vincularFaq(master)} />
                }                 
                <ButtonToolTip title='Editar item' color='blue' icon={<EditIcon />} onClick={() => editarItem()} />
                <ButtonToolTip title='Remover Item' color='red' icon={<Delete />} onClick={() => removeItem(master.codigo)} />
            </>}
        >
            <Box p={1}>
                {showForm ?
                    <form onSubmit={handleSubmit}>
                        <TitleSmall title='Item Rastreio' />
                        <TextFieldButton2 name='descricao' onChange={handleChange} onClickCancel={() => cancelarItem()} value={item.descricao} onClickCheck={() => addItem()} />
                    </form>
                    : ''}
                <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMoreIcon color='iconColor' />}
                    defaultExpandIcon={<ChevronRightIcon color='iconColor' />}
                    defaultExpanded={['0']}
                >
                    <TreeItem nodeId={'0'} key={0} label={<TitleGrupos title='Menu' />} onClick={() => setMaster({ codigo: 0, descricao: 'Menu', master: 0 })} >
                        {loadingFAQ ? '' : faqMenu.filter(it => it.master === 0).map(ListarItens)}
                    </TreeItem>
                </TreeView>
            </Box>
            {showDialogRef ?
                <FAQDialogRef open={showDialogRef} onClose={handleClose}>
                    {listFaqApro.map(ListarFaq)}
                </FAQDialogRef>
                : ''}
        </ContainerPages >
    );
}