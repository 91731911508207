import * as React from 'react';
import { ContainerPages } from '../../components/container';
import { Box, Grid, List, ListItem, ListItemText, } from '@mui/material';
import { TextFieldButton2 } from '../../components/textfield';
import useTag from '../../hooks/useTag';
import { Add, Delete } from '../../components/icons';
import { TextConteudo } from '../../components/typography';
import { ButtonToolTip } from '../../components/button';
import { orderList } from '../../utils/geral';

export default function Marcadores() {
    const { listTags, addRemove, getTags } = useTag();
    const [openText, setOpenText] = React.useState(false);
    const [nome, setNome] = React.useState('');
    const [atualizar, setAtualizar] = React.useState(false);
    const [focus, setFocus] = React.useState('');

    React.useEffect(() => {
        getTags();
    }, [atualizar])

    const handleChange = (event) => {
        setNome(event.target.value);
    }

    function Tags(tag, t) {
        return (<ListItem
            key={t}
            disableGutters
            onMouseOver={() => setFocus(tag.nome)} onMouseOut={() => setFocus('')}
        >
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <ListItemText primary={<TextConteudo text={tag.nome} />} />
                {focus === tag.nome ? <ButtonToolTip title='Excluir' color='red' icon={<Delete fontSize='18px' />} onClick={() => addRemoveTag(tag.nome, 'delete')} size='small' /> : ''}
            </span>
        </ListItem >
        );
    }

    function showTextField() {
        setNome('');
        setOpenText(!openText);
    }

    function addRemoveTag(nome, metodo) {
        if (metodo === 'post')
            addRemove(nome, 'post');
        else
            addRemove(nome, 'delete')
        setOpenText(false);
        setNome('');
        setAtualizar(!atualizar);
    }

    return <ContainerPages ativarLogo={true} activeAuxBar={true} overflow={true} title='Marcadores' btnAux={<ButtonToolTip title='Nova Tag' color='green' icon={<Add />} onClick={() => showTextField()} />}>
        <Box p={1} >
            {openText ?
                <TextFieldButton2 label='Nome do Item' value={nome} onChange={handleChange} onClickCancel={() => showTextField()} onClickCheck={() => addRemoveTag(nome, 'post')} />
                : ''}
        </Box>
        <List sx={{ bgcolor: 'inherit' }}>
            {orderList(listTags, "nome").map(Tags)}
        </List >
    </ContainerPages >
}