import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { Box, DialogTitle, Grid, Stack, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { HeaderSac } from '../sac/header';
import { BodySac } from '../sac/body';
import { SacContext } from '../../context';
import { TextDescricaoLarge, Title } from '../typography';
import { TextFieldDefault, TextFieldResizes } from '../textfield';
import { ButtonConcluido } from '../button';
import { ResizeIcon } from '../icons';
import DialogAnexos from '../sac/components/dialog/anexos';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function AlertDialog(props) {
    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent style={{ background: `${props.tipo === 'success' ? 'green' : 'red'}` }}>
                <DialogContentText id="alert-dialog-slide-description" style={{ color: 'white', textAlign: 'justify' }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={2}>
                            {props.tipo === 'success' ?
                                <CheckCircleIcon style={{ fontSize: '78px' }} /> :
                                <ErrorIcon style={{ fontSize: '78px' }} />}
                        </Grid>
                        <Grid item xs={10}>
                            <Box p={2} style={{ textAlign: 'center' }}>
                                {props.tipo === 'success' ?
                                    <>
                                        <Typography style={{ fontSize: '20px' }}>Solicitação enviada com sucesso!</Typography>
                                        <Typography style={{ fontSize: '16px' }}>Breve entraremos em contato.</Typography>
                                        <Typography style={{ fontSize: '14px' }}>Protocolo: {props.protocolo}</Typography>
                                    </>
                                    :
                                    <>
                                        <Typography style={{ fontSize: '20px' }}>Solicita não enviado!</Typography>
                                        <Typography style={{ fontSize: '16px' }}>Entre em contato conosco.</Typography>
                                        <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <LocalPhoneIcon /><WhatsAppIcon /> <Typography style={{ fontSize: '17px' }}>(28) 3521-1321</Typography>
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <WhatsAppIcon /> <Typography style={{ fontSize: '17px' }}>(28) 99885-9118</Typography>
                                        </Stack>

                                    </>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}


export function SacDialog(props) {
    const { sac } = React.useContext(SacContext);
    const atendimento = sac;
    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            maxWidth={'lg'}
            aria-describedby="alert-dialog-slide-description"
            onClose={props.onClose}
        >
            <DialogContent style={{ background: 'inherit', textAlign: 'justify' }} >
                <HeaderSac
                    atendimento={atendimento}
                    codigo={atendimento.codigo}
                    status={atendimento.status}
                    showMenu={atendimento.status !== 'F'}
                    showMenuGeral={atendimento.status !== 'F'}
                />
                <BodySac atendimento={atendimento} />
            </DialogContent>
        </Dialog>
    );
}

export function DescrDialog(props) {
    const atendimento = props.atendimento;

    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            maxWidth='xl'
            aria-describedby="alert-dialog-slide-description"
            onClose={props.onClose}

        >
            <pre style={{ textWrap: 'wrap', padding: '15px' }}>
                <TextDescricaoLarge text={atendimento.conteudos[props.idDescr - 1].descricao} />
            </pre>
        </Dialog >
    );
}

export function FAQDialog(props) {
    if (props.descricao) {
        document.getElementById('conteudoZoom').innerHTML = props.descricao;
    }
    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            maxWidth='lg'
            aria-describedby="alert-dialog-slide-description"
            onClose={props.onClose}
            style={{
                // color: getTheme() === 'dark' ? '#f4f5fa' : '#343944',
                padding: '10px',
                // marginTop: '10px',
                // fontSize: '14px'
            }}

        >
            <div id='conteudoZoom' style={{ padding: '10px', textAlign: 'justify' }}></div>

        </Dialog >
    );
}

export function FAQDialogRef(props) {
    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            onClose={props.onClose}
        >
            <Box p={4}>
                {props.children}
            </Box>
        </Dialog >
    );
}

export function FAQDialogRefLink(props) {
    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-link"
            onClose={props.onClose}
        >
            <Box p={1} style={{ width: '450px' }}>
                <DialogTitle variant='button'>Infomações do Link</DialogTitle>
                <TextFieldDefault size='small' id='descricao' name='descricao' placeholder='Descrição' onChange={props.onChange} value={props.valueDescr} autoFocus={true} />
                <p />
                <TextFieldDefault size='small' id='link' name='link' placeholder='Endereço' onChange={props.onChange} value={props.valueLink} />
                <div style={{ marginTop: '10px', textAlign: 'right' }}>
                    <ButtonConcluido onClick={props.onClick} />
                </div>
            </Box>
        </Dialog >
    );
}

export function DialogResizeImage(props) {
    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-link"
            onClose={props.onClose}
        >
            <Box p={1} style={{ width: '300px', textAlign: 'center' }}>
                <DialogTitle variant='subtitle1'>Redimensionar Imagem</DialogTitle>
                <TextFieldResizes label='Largura' value={props.valueWidth} onChange={props.onChangeW} placeholder="Largura" />
                <p />
                <TextFieldResizes label='Altura' value={props.valueHeight} onChange={props.onChangeH} placeholder="Altura" />
                <div style={{ marginTop: '10px', textAlign: 'right' }}>
                    <ButtonConcluido onClick={props.onClick} />
                </div>
            </Box>
        </Dialog >
    );
}