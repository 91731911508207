import * as React from 'react';
import useTag from '../../../../../hooks/useTag';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Switch } from '@mui/material';
import { ButtonFechar } from '../../../../button';
import { SacContext } from '../../../../../context';
import { listaLength } from '../../../../../utils/string';
import { TitleGrupos } from '../../../../typography';

export default function DialogMarcadores() {
    const { listTags, getTags, tagSac, loading } = useTag();
    const { toggleAcao, sac } = React.useContext(SacContext);
    const [novaLista, setNovaLista] = React.useState([]);

    function carregarTagSac() {
        let lista = listTags;
        const listaTagsSac = sac.tags.split(',');
        for (let i = 0; i < listaLength(lista); i++) {
            for (let j = 0; j < listaLength(listaTagsSac); j++) {
                if (listaTagsSac[j] === lista[i].nome)
                    lista[i].check = true;
            }
        }
        return lista;
    }

    React.useEffect(() => {
        getTags();
    }, [])

    React.useEffect(() => {
        setNovaLista(carregarTagSac());
    }, [listTags, sac])

    React.useEffect(() => {
        toggleAcao(sac.codigo, 'Marcadores');
    }, [loading])

    function addRemoveTag(tag) {
        if (!tag.check) {
            tagSac(sac.codigo, tag.nome, 'DELETE');
        } else {
            tagSac(sac.codigo, tag.nome, 'POST');
        }
    }

    const handleToggle = (index) => {
        novaLista[index].check = !novaLista[index].check;
    }

    function Tags(tag, t) {
        return (<ListItem
            disablePadding
            onClick={() => addRemoveTag(tag)}
        >
            <ListItemButton onClick={() => handleToggle(t)} dense>
                <ListItemIcon>
                    <Switch
                        edge="start"
                        size='small'
                        checked={tag.check}
                        disableRipple
                        inputProps={{ 'value': tag.nome }}
                        color='green'
                    />
                </ListItemIcon>
                <ListItemText id={tag.nome} primary={tag.nome} />
            </ListItemButton>
        </ListItem>
        );
    }

    return (<>
        <Divider textAlign="left" style={{ margin: '10px 0px 10px 0px' }}><TitleGrupos title='Marcadores' /></Divider>
        <List sx={{ background: 'inherit' }}>
            {novaLista ? novaLista.map(Tags) : ''}
        </List >
        <Stack direction='row' spacing={1} justifyContent='flex-end' paddingRight={1} paddingBottom={1}>
            <ButtonFechar />
        </Stack>
    </>);
}
