import React, { useContext } from 'react';
import { NavBar, NavBarSimple } from '../appbar';
import Container from '@mui/material/Container';
import {SearchBar} from '../search';
import { GeralContext } from '../../context';
import AuxBar from '../auxbar';
import SnackbarAlert from '../snackbar';
import { Paper } from '@mui/material';
import { LayoutEmail } from '../layout';
import { getLayout } from '../../utils/theme';

export function ContainerPages(props) {
    const { ativarPesquisa, mensagem, screen } = useContext(GeralContext);

    return (
        <Container maxWidth='xxl' style={{ margin: '0', padding: '0', height: screen.height, width: screen.width }}>
            <div style={{ height: '60px', marginBottom: '0px' }}>
                {props.navBarSimple ? <NavBarSimple /> : <NavBar />}
            </div>
            {ativarPesquisa && window.innerWidth < 768 ?
                <Paper elevation={0}>
                    <div style={{ height: '120px', padding: '10px', paddingRight: '40px' }}>
                        <SearchBar />
                    </div>
                </Paper> : ''}
            {props.activeAuxBar ?
                <div style={{ height: '60px', marginTop: '15px', padding: '0 10px' }}>
                    <AuxBar title={props.title} btnAux={props.btnAux} toVoltar={props.toVoltar} ocultarBotoes={props.ocultarBotoes} />
                </div>
                : ''}
            <div style={{ height: `${(screen.height - 60) - (props.activeAuxBar ? 60 : 0)}px`, padding: '0 5px', overflow: (props.overflow && !props.ativarLogo) || (screen.width <= 768) || (getLayout() === 'padrao') ? 'auto' : 'inherit' }}>
                {props.ativarLogo && window.innerWidth > 768 ?
                    <LayoutEmail
                        colL={<Paper sx={{ height: `${(screen.height - 60) - (props.activeAuxBar ? 60 : 0)}px`, textAlign: 'center' }} elevation={1}>
                            <img src='../../../../../../app-assets/img/logo/Logo.svg' alt='logo redsis' height={500} />
                        </Paper>}
                        colR={props.children} />
                    :
                    props.children
                }
            </div>
            <SnackbarAlert msg={mensagem} />
        </Container>
    );
}

export function SimpleContainerRedsis(props) {
    const { screen } = useContext(GeralContext);

    return (
        <Container maxWidth='xxl' style={{ margin: '0', padding: '0', height: screen.height, width: screen.width }}>
            {props.children}
        </Container>
    )

}