import { getTheme } from "../utils/theme";

const tema = getTheme();

export const navBarTheme = () => {
  if (tema === 'dark')
    return {
      styleOverrides: {
        root: {
          background: '#30353f',
          height: '70px',
          paddingTop: '5px'
        },
      }
    }
  else
    return {
      styleOverrides: {
        root: {
          background: '#f4f5fa',
          height: '70px',
          paddingTop: '5px'

        },
      }
    }
};

export const containerTheme = () => {
  if (tema === 'dark')
    return {
      styleOverrides: {
        root: {
          background: '#343944',
          overflow: 'hidden',
          padding: '0px !important'
        },
      }
    }
  else
    return {
      styleOverrides: {
        root: {
          background: '#e9e9e9',
          overflow: 'hidden',
          padding: '0px !important'
        },
      }
    }
}
export const typographyTheme = () => {
  if (tema === 'dark')
    return {
      styleOverrides: {
        root: {
          color: '#f4f5fa',
        },
      }
    }
  else
    return {
      styleOverrides: {
        root: {
          color: '#343944',
        },
      }
    }
}
export const drawerTheme = () => {
  if (tema === 'dark')
    return {
      styleOverrides: {
        root: {
          // background: '#343944',
        },
      }
    }
  else
    return {
      styleOverrides: {
        root: {
          // background: '#f4f5fa',
        },
      }
    }
}
export const paperTheme = () => {
  if (tema === 'dark')
    return {
      styleOverrides: {
        root: {
          background: '#30353f',
          margin: '0px'
        },
      }
    }
  else
    return {
      styleOverrides: {
        root: {
          background: '#f4f5fa',
          margin: '0px'
        },
      }
    }
}
export const menuItemTheme = () => {
  if (tema === 'dark')
    return {
      styleOverrides: {
        root: {
          background: '#343944',
        },
      }
    }
  else
    return {
      styleOverrides: {
        root: {
          background: '#f4f5fa',
        },
      }
    }
}
export const textFieldTheme = () => {
  if (tema === 'dark')
    return {
      styleOverrides: {
        root: {
          background: '#343944',
        },
      }
    }
  else
    return {
      styleOverrides: {
        root: {
          background: '#e9e9e9',
        },
      }
    }
}

export const inputBaseTheme = () => {
  if (tema === 'dark')
    return {
      styleOverrides: {
        root: {
          backgroundColor: 'inherit',
          color: '#f4f5fa',

        },
      }
    }
  else
    return {
      styleOverrides: {
        root: {
          backgroundColor: '#inherit',
          color: '#343944',
        },
      }
    }

}

export const formControTheme = () => {
  if (tema === 'dark')
    return {
      styleOverrides: {
        root: {
          background: 'inherit',
          color: '#f4f5fa',
        },
      }
    }
  else
    return {
      styleOverrides: {
        root: {
          backgroundColor: 'inherit',
          color: '#343944',
        },
      }
    }
}

export const inputLabelTheme = () => {
  if (tema === 'dark')
    return {
      styleOverrides: {
        root: {
          color: '#f4f5fa',
        },
      }
    }
  else
    return {
      styleOverrides: {
        root: {
          color: '#343944',
        },
      }
    }
}