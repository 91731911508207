import * as React from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { getTheme, heightScreen } from '../../utils/theme';
import { listaLength } from '../../utils/string';
import { GeralContext } from '../../context';
import { filtrarLista } from '../../utils/geral';
import useFaq from '../../hooks/useFaq';
import useRastreios from '../../hooks/useRastreios';
import useUsuarios from '../../hooks/useUsuarios';
import { iniFaq } from '../../utils/constructor';
import { Title, TitleSmall } from '../../components/typography';
import { getPerm, getUser } from '../../utils/user';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Add, Delete, EditIcon, Thumb, ZoomIcon } from '../../components/icons';
import { FAQDialog } from '../../components/dialog';
import LayoutSac from '../../components/layout';
import { DividerBasic } from '../../components/divider';
import { ButtonLinkIcon, ButtonToolTip } from '../../components/button';
import { ContainerPages } from '../../components/container';
import { BoxFaq } from '../../components/box';

export default function ListFaqPend() {
    const { getFaqAprovados, getFaqPendentes, listFaqPend, aprovFaq, deleteFaq, loading } = useFaq();
    const { getRastreios, rastreios } = useRastreios();
    const [faqSelect, setFaqSelect] = React.useState(iniFaq);
    const { } = useUsuarios();
    const [atualizar, setAtualizar] = React.useState(false);
    const [open, setOpen] = React.useState(false)
    const { listUsers } = React.useContext(GeralContext);


    function setElemento(faq) {
        if (faq.codigo === faqSelect.codigo) {
            document.getElementById('conteudo').innerHTML = '';
            setFaqSelect(iniFaq);
        }
        else {
            document.getElementById('conteudo').innerHTML = getTheme() === 'light' ? faq.descricao.replaceAll('(241, 241, 241)', '(51, 51, 51)')
            : faq.descricao.replaceAll('(51, 51, 51)', '(241, 241, 241)');
            setFaqSelect(faq)
        }
    }

    React.useEffect(() => {
        getRastreios();
        getFaqAprovados();
        getFaqPendentes();
        setAtualizar(false);
        setFaqSelect(iniFaq);
        document.getElementById('conteudo').innerHTML = '';
    }, [loading === false, atualizar === true])

    function aprovarFaq() {
        aprovFaq(faqSelect.codigo)
        setAtualizar(true);
    }

    function excluirFaq(codigo) {
        deleteFaq(codigo);
        setAtualizar(true);
    }

    function SelecionarFaq(faq) {
        setFaqSelect(faq)
        setElemento(faq)
    }

    function ListarItens(item) {
        return <BoxFaq onClick={() => SelecionarFaq(item)} faq={item} />
    }

    function DetalhesFaq() {
        let listaRastreio = []
        listaRastreio.push(filtrarLista(rastreios, 'codigo', faqSelect.rastreio)[0]);
        if (listaLength(listaRastreio) > 0) {
            for (let i = 0; i < listaLength(listaRastreio); i++) {
                if (listaRastreio[i].master > 0) {
                    listaRastreio.push(filtrarLista(rastreios, 'codigo', listaRastreio[i].master)[0]);
                }
            }
        }

        function ListDescrRastreio(r) {
            return <Stack key={r.codigo} direction='row' alignItems='center'>
                {r.master === 0 ? '' : <KeyboardArrowRightIcon color='iconColor' />}
                <TitleSmall title={r.descricao} />
            </Stack>
        }

        return <Box>
            <Grid container>
                <Grid item xs={12} md={10}>
                    <Title title={faqSelect.titulo} />
                    <Stack direction='row'>{listaLength(listaRastreio) > 0 ? listaRastreio.map(ListDescrRastreio).reverse() : ''}</Stack>
                    {faqSelect.criadopor ? <TitleSmall title={`Criado por ${filtrarLista(listUsers, 'codigo', faqSelect.criadopor)[0].login}`} /> : ''}
                    {faqSelect.aprovadopor ? <TitleSmall title={faqSelect.aprovadopor > 0 ? `Aprovado por ${filtrarLista(listUsers, 'codigo', faqSelect.aprovadopor)[0].login}` : ''} /> : ''}
                </Grid>
                <Grid item xs={12} md={2} style={{ textAlign: 'end' }}>
                    {getPerm('LI') ? <ButtonToolTip title='Aprovar' color='green' icon={<Thumb />} onClick={() => aprovarFaq()} /> : ''}
                    {getPerm('LI') || (faqSelect.criadopor === parseInt(getUser().sub)) ? <ButtonLinkIcon title='Editar' color='blue' icon={<EditIcon color='blue' />} to={`/faq/edit/${faqSelect.codigo}`} /> : ''}
                    {/* {getPerm('LI') || (faqSelect.criadopor === parseInt(getUser().sub)) ? <ButtonToolTip title='Excluir' color='red' icon={<Delete />} onClick={() => excluirFaq(faqSelect.codigo)} /> : ''} */}
                    <ButtonToolTip title='Ampliar' color='iconColor' icon={<ZoomIcon />} onClick={() => setOpen(true)} />
                </Grid>
            </Grid>
        </Box >
    }



    return (<ContainerPages activeAuxBar={true} title='FAQ - Pendentes' toVoltar='/faq'
        btnAux={<ButtonLinkIcon title='Novo FAQ' icon={<Add color='green' />} to='/faq/create' />}>
        <LayoutSac
            padrao={listaLength(listFaqPend) > 0 ? listFaqPend.map(ListarItens) : <Title title='Não existe FAQ pendente de aprovação.' />}
            colL={listaLength(listFaqPend) > 0 ? listFaqPend.map(ListarItens) : <Title title='Não existe FAQ pendente de aprovação.' />}
            colR={<Box p={1}>
                {faqSelect.codigo > 0 ?
                    <>
                        <DetalhesFaq />
                        <DividerBasic />
                    </> : ''}

                <div id='conteudo'
                    style={{
                        color: getTheme() === 'dark' ? '#f4f5fa' : '#343944',
                        padding: '10px',
                        fontSize: '14px',
                        textAlign: 'justify'
                    }}>
                    <img src='./app-assets/img/logo/Logo.svg' alt='logo redsis' style={{ height: heightScreen(-200), width: '100%', opacity: '0.1', WebkitFilter: 'grayscale(100%)', filter: 'grayscale(100%)' }} />
                </div>
            </Box>}
        />

        <FAQDialog open={open} descricao={faqSelect.descricao} onClose={() => setOpen(false)} />
    </ContainerPages>
    );
}