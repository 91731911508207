import * as React from 'react';
import { Box, ButtonGroup, Paper, Typography } from '@mui/material';
import { StackJustify } from '../stack';
import { Link } from 'react-router-dom';
import { GeralContext } from '../../context';
import { ButtonToolTip } from '../button';
import { Back } from '../icons';

export default function AuxBar(props) {
    const { toggleAtualizar } = React.useContext(GeralContext);

    return (
        <Box style={{ borderRadius: '7px' }}>
            <Paper elevation={0} >
                <StackJustify>
                    <Typography style={{
                        boxSizing: 'border-box',
                        borderBottom: `2px solid #ed3237`,
                        margin: '0px 0px 0px 7px',
                        fontWeight: '500',
                        fontSize: '0.975rem',
                        letterSpacing: '0.02857em',
                        maxWidth: '250px',
                        padding: '12px 16px 12px',
                    }}>
                        {props.title}
                    </Typography>
                    {props.ocultarBotoes ? '' :
                        <ButtonGroup
                            color="primary"
                            size="large"
                            variant="text"
                        >
                            {props.btnAux}
                            <Link to={props.toVoltar ? props.toVoltar : '/sac'} onClick={() => toggleAtualizar(true)}><ButtonToolTip title='Voltar' icon={<Back color='orange' />} /></Link>
                        </ButtonGroup>
                    }
                </StackJustify>
            </Paper>
        </Box >
    );
}