import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Route from "./route";
import { BrowserRouter } from "react-router-dom";
import ThemeRedsis from "./providers/theme";
import { GeralProvider, SacProvider } from "./context";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <GeralProvider>
      <SacProvider>
        <ThemeRedsis>
          <BrowserRouter>
            <Route />
          </BrowserRouter>
        </ThemeRedsis>
      </SacProvider>
    </GeralProvider>
  // </React.StrictMode>
);

