import { Box } from "@mui/material";
import { getLayout, getTheme } from "../../utils/theme";
import { getSetor } from "../../utils/sac";
import { useContext, useState, useEffect } from "react";
import { GeralContext } from "../../context";

export function LayoutEmail(props) {
    const alturaPadrao = { altura: visualViewport.height + 'px', alturaMenu: '60px', alturaCorpo: (visualViewport.height - 115) + 'px', width: '100vw', alturaTitle: '35px' };
    const borda = `5px solid ${getTheme() === 'dark' ? '#343944' : '#e9e9e9'}`;

    return (
        <div style={{ display: 'flex', height: alturaPadrao.alturaCorpo, border: borda, borderTop: '0' }}>
            {visualViewport.width > 768 ?
                <>
                    <div style={{ width: '35%', padding: '0px 10px', borderRight: borda, overflow: 'auto', minHeight: '100%' }}>
                        {props.colL}
                    </div>
                    <div style={{ width: '65%', padding: '10px', background: getTheme() === 'dark' ? '#343944' : '#e9e9e9', overflow: 'auto' }}>
                        {props.colR}
                    </div>
                </>
                :
                <div style={{ width: '100%', padding: '10px', background: getTheme() === 'dark' ? '#343944' : '#e9e9e9' }}>
                    destaque
                </div>
            }
        </div >
    );
}

export function LayoutPadrao(props) {
    const { screen, ativarPesquisa } = useContext(GeralContext);

    return (
        <Box sx={{
            height: `${(screen.height - 150) - (ativarPesquisa ? 60 : 0) - (props.activeAuxBar ? 60 : 0)}px`,
            overflow: 'auto',
        }} >
            {props.children}
        </Box>
    );
}

export default function LayoutSac(props) {
    const alturaPadrao = { altura: visualViewport.height + 'px', alturaMenu: '60px', alturaCorpo: (visualViewport.height - 115) + 'px', width: '100vw', alturaTitle: '35px' };
    const borda = `5px solid ${getTheme() === 'dark' ? '#343944' : '#e9e9e9'}`;
    

    const [, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        setTimeout(() => {
            function handleResize() {
                setWindowDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
            window.addEventListener('resize', handleResize);
            handleResize();
            return () => window.removeEventListener('resize', handleResize);
        }, 5000)

    }, [])

    return (
        <div style={{ display: 'flex', height: alturaPadrao.alturaCorpo, border: borda, borderTop: '0' }}>
            {getLayout() === 'padrao' ||  visualViewport.width <= 768 ?
                <div style={{ width: '100%', padding: visualViewport.width <= 768 ? '0' :'10px', background: getTheme() === 'dark' ? '#343944' : '#e9e9e9', overflow: visualViewport.width <= 768 ?  'auto' : 'inherit' }}>
                    {props.padrao}
                </div>
                :
                <>
                    <div style={{ width: '40%', padding: '10px', borderRight: borda, overflow: 'auto', marginBottom: '35px' }}>
                        {props.colL}
                    </div>
                    <div style={{ width: '60%', padding: '10px', overflow: 'auto', marginBottom: '35px' }}>
                        {props.colR}
                    </div>
                </>
            }
        </div >

    )
}



