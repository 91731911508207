import React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import { CloseIcon, Check } from '../icons';

export function TextFieldDefault(props) {
    return (
        <Box sx={{ pl: 1, pr: 1, marginLeft: '0' }}>
            <TextField id={props.id} autoFocus={props.autoFocus} name={props.name} type={props.type} label={props.label} onChange={props.onChange} value={props.value || ''} disabled={props.disabled} fullWidth autoComplete='off'
                variant="filled" multiline={props.multiline} placeholder={props.placeholder} rows={props.rows} hidden={props.hidden} size={props.size}
            />
        </Box>
    );
}
export function TextFieldResizes(props) {
    return (
        <Box sx={{ pl: 1, marginTop: '5px' }}>
            <TextField id={props.id} label={props.label} name={props.name} type="number" style={{ textAlign: 'center' }} onChange={props.onChange} value={props.value || ''} fullWidth size='small' autoComplete='off' variant="standard" />
        </Box>
    );
}

export function TextFieldButton(props) {
    return (
        <Box sx={{ p: 1 }}>
            <FormControl variant="standard" fullWidth >
                <InputLabel htmlFor="standard-adornment-password">{props.label}</InputLabel>
                <Input
                    id={props.id}
                    name={props.name}
                    type='text'
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    fullWidth
                    autoFocus={props.autoFocus}
                    disabled={props.disabled}
                    autoComplete='off'
                    multiline={props.multiline}
                    rows={props.rows}
                    endAdornment={
                        <InputAdornment position="end" >
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={props.onClick}
                                type={props.typeButton}
                            >

                                {props.icon}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </Box>
    );
}

export function TextFieldButton2(props) {
    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
            <Input
                id={props.id}
                name={props.id}
                onChange={props.onChange}
                value={props.value}
                autoComplete='off'
                autoFocus={true}
                multiline={props.multiline}
                rows={props.rows}
                size={props.size}
                style={props.style}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={props.onClickCheck}
                            type={props.typeButton}
                        >
                            <Check color='green' />
                        </IconButton>
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={props.onClickCancel}
                        >
                            <CloseIcon color='red' />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    )
}


