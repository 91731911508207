import * as React from 'react';
import { TextFieldButton } from '../../../../textfield';
import { Search } from '@mui/icons-material';
import { Box, Divider } from '@mui/material';
import { ButtonFechar } from '../../../../button';
import useSac from '../../../../../hooks/useSac';
import SacResumo from '../../../resumo';
import { TitleGrupos } from '../../../../typography';

export default function DialogVincular() {
    const [codigo, setCodigo] = React.useState();
    const { getSac, sac } = useSac();
    const atendimento = sac;

    const handleChange = (event) => {
        setCodigo(event.target.value)
    }

    return (
        <>
            <Divider textAlign="left" style={{ margin: '10px 0px 10px 0px' }}><TitleGrupos title='Vincular atendimentos' /></Divider>
            <TextFieldButton id='codigo' name='codigo' label='ID principal' value={codigo} autoFocus={true} onChange={handleChange} onClick={() => getSac(codigo)} icon={<Search />} />
            <br />
            {atendimento.codigo > 0 ? <SacResumo atendimento={atendimento} vincular={true} color={true}/> : ''}
            <Box style={{ textAlign: 'end' }} p={1}>
                <ButtonFechar />
            </Box>
        </>
    );
}
