import * as React from 'react';
import { formatInputDateTime } from '../../../../../utils/date';
import { getUser, permRender } from '../../../../../utils/user';
import { Box, Divider, FormControlLabel, IconButton, List, ListItem, Stack, Switch } from '@mui/material';
import { SelectAssuntos, SelectUsuarios, SelectSetores, SelectSoftware, SelectPrioridade } from '../../../../select';
import { TextFieldButton, TextFieldDefault } from '../../../../textfield';
import { Add, Delete, Send } from '../../../../icons';
import { ButtonCancelar, ButtonIcon } from '../../../../button';
import useSac from '../../../../../hooks/useSac';
import { GeralContext, SacContext } from '../../../../../context';
import { TextConteudo, TitleGrupos } from '../../../../typography';
import { getThemeContraste } from '../../../../../utils/theme';
import { StackLeft } from '../../../../stack';
import { getSetor, obgAssunto } from '../../../../../utils/sac';
import { getMensagemFinal, listaLength } from '../../../../../utils/string';
import DialogAnexos from '../anexos';

export default function DialogSac(props) {
    const [sac, setSac] = React.useState({ codigo: undefined, tipo: 'AT', pessoa: null, assunto: '', motivo: '', motivos: [], prioridade: '', usuario: null, direto: false, retornado: false, destino: '', caminhobd: '', solucao: '', software: '', trocaAssunto: 0, versao: '' });
    const sacEdit = props.atendimento;
    const [motivos, setMotivos] = React.useState([]);
    const [texto, setTexto] = React.useState('');
    const { acao, toggleAcao, toggleOpen, codigo } = React.useContext(SacContext);
    const { toggleMensagem } = React.useContext(GeralContext);
    const { putSac } = useSac();

    React.useEffect(() => {
        setSac({
            tipo: sacEdit.tipo === 'AN' ? 'PG' : sacEdit.tipo,
            pessoa: { CODPESSOA: sacEdit.codpessoa, NOM_PESS: sacEdit.pessoa },
            assunto: sacEdit.codassunto,
            prioridade: sacEdit.prioridade,
            codigo: sacEdit.codigo,
            contato: sacEdit.contato,
            conteudo: sacEdit.conteudo,
            software: sacEdit.software,
            datahora: formatInputDateTime(Date.now()),
            usuario: { codigo: getUser().sub, login: getUser().nome },
            caminhobd: sacEdit.caminhobd,
            data_copia: formatInputDateTime(Date.now()),
            direto: false,
            versao: sacEdit.versao_copia
        });
        const msgFinal = getMensagemFinal();
        if ((acao === 'Finalizar') && (msgFinal !== null) && (sacEdit.tags.includes(msgFinal.tag))) {
            setSac((prevalue) => {
                return {
                    ...prevalue,
                    "solucao": msgFinal.solucao,
                    "versao": msgFinal.versao,
                }
            })
        }
        // eslint-disable-next-line
    }, []);

    const handleChange = (event) => {
        let name = event.target.name;
        let value = name === 'retornado' | name === 'direto' ? event.target.checked :
            name === 'motivo' | name === 'motivos' | name === 'texto' | name === 'solucao' ? event.target.value.replace(/\{/g, "[").replace(/\}/g, "]") : event.target.value;
        setSac((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        })
        if (name === 'assunto') {
            let p = JSON.parse(sessionStorage.getItem('/sac/assuntos'));
            for (let i = 0; i < listaLength(p); i++) {
                if (p[i].ID === value) {
                    setSac((prevalue) => {
                        return {
                            ...prevalue,
                            prioridade: p[i].prioridade
                        }
                    })
                }
            }
        }
    }

    function listaMotivos(motivo, m) {
        return <ListItem key={m}
            secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => removeMotivos(motivo)} >
                    <Delete color='red' />
                </IconButton>
            }
        ><TextConteudo text={motivo} /></ListItem>
    }

    function addMotivos() {
        if (texto) {
            setMotivos([...motivos, texto]);
            setSac((prevalue) => {
                return {
                    ...prevalue,
                    'motivos': [...motivos, texto]
                }
            })
            setTexto('');
        }
    }

    const removeMotivos = (itemRemove) => {
        let lista = motivos.filter(item => item !== itemRemove);
        setMotivos(lista);
        setSac((prevalue) => {
            return {
                ...prevalue,
                'motivos': lista
            }
        })
    };


    function salvar() {
        const cod = sac.codigo;
        if (acao === 'Direcionar' && sac.motivos === undefined) {
            toggleMensagem('Nenhum motivo de direcionamento adicionado.', 'warning');
            return;
        }
        if (
            (acao === 'Retornar' && (sac.motivo === '' || sac.assunto === '' || sac.motivo === undefined || sac.assunto === undefined)) ||
            (acao === 'Direcionar' && (sac.motivos === undefined || sac.software === '')) ||
            (acao === 'Agendar' && (sac.motivo === '' || sac.datahora === '')) ||
            (acao === 'Anotacoes' && sac.texto === '') ||
            (acao === 'Reservar' && sac.usuario === '') ||
            (acao === 'Finalizar' && (sac.solucao === '' || sac.solucao === undefined || sac.assunto === ''))
        ) {
            toggleMensagem('Todos os campos devem ser preenchidos!', 'warning');
            return;
        } else if (acao === 'Direcionar' && sac.tipo === sacEdit.tipo) {
            toggleMensagem('Selecione um setor para o direcionamento.', 'warning');
            return;
        }
        if (obgAssunto(sac.assunto) && acao === 'Finalizar') {
            toggleMensagem(`Não é possivel ${acao} com este assunto.`, 'warning');
            return;
        } else {
            putSac(acao, sac);
            if (acao === 'Finalizar' || acao === 'Direcionar') {
                toggleAcao(0, 'sac');
                toggleOpen(false)
            }
            else
                toggleAcao(cod, 'sac');
        }
    }

    return (
        <>
            <Divider textAlign="left" style={{ margin: '10px 0px 10px 0px' }}><TitleGrupos title={acao} /></Divider>
            {acao === 'Reservar' ?
                <>
                    <SelectUsuarios id='usuario' onChange={(event, value) => setSac((prevalue) => {
                        return {
                            ...prevalue,
                            'usuario': value
                        }
                    })} value={sac.usuario} label='Usuário' />
                </>
                :
                acao === 'Retornar' ?
                    <>
                        <TextFieldDefault id='motivo' name='motivo' autoFocus={true} onChange={handleChange} value={sac.motivo} label='Motivo' multiline rows={5} />
                        <SelectAssuntos id='assunto' onChange={handleChange} value={sac.assunto} label='Assunto' externo={false} />
                    </>
                    :
                    acao === 'Direcionar' ?
                        <>
                            <SelectSetores onChange={handleChange} value={sac.tipo} label='Setor Destino' />
                            <TextFieldButton id='motivos' label='Motivos do Direcionamento' icon={<Add color='green' />} value={texto} onChange={(e) => setTexto(e.target.value)} onClick={() => addMotivos()} multiline rows={3} />
                            {listaLength(motivos) ? <Divider textAlign="left" style={{ margin: '10px 0px 0px 0px', color: '#8b8c8b' }}><TitleGrupos title="Lista de Motivos" /></Divider> : ''}
                            <List>
                                {motivos.map(listaMotivos)}
                            </List>
                            <TextFieldDefault id='caminhobd' name='caminhobd' onChange={handleChange} value={sac.caminhobd} label='Caminho DB' />
                            <SelectSoftware onChange={handleChange} value={sac.software} defaultValue={'REDSIS 4.0.0'} label='Software' />
                            <SelectAssuntos onChange={handleChange} value={sac.assunto} label='Assunto' externo={false} />
                            <SelectPrioridade onChange={handleChange} value={sac.prioridade} label='Prioridade' />
                            {permRender('DR', <FormControlLabel control={<Switch id='retornado' name='retornado' value={sac.retornado} onChange={handleChange} color='green' />} label="Retornado?" style={{ color: getThemeContraste() }} />)}
                            {sac.retornado ?
                                <SelectUsuarios id='usuario' onChange={(event, value) => setSac((prevalue) => {
                                    return {
                                        ...prevalue,
                                        'usuario': value
                                    }
                                })} value={sac.usuario} label='Reservar para' />
                                : ''
                            }
                            <DialogAnexos acao={acao} codigo={codigo} status={sac.status} />
                        </>
                        :
                        acao === 'Agendar' ?
                            <>
                                <TextFieldDefault id='motivo' name='motivo' autoFocus={true} onChange={handleChange} value={sac.motivo} label='Motivo' multiline rows={5} />
                                <TextFieldDefault id='datahora' name='datahora' type='datetime-local' onChange={handleChange} value={sac.datahora} label='Data e Hora' />
                            </>
                            :
                            acao === 'Anotacoes' ?
                                <>
                                    <TextFieldDefault id='texto' name='texto' autoFocus={true} onChange={handleChange} value={sac.texto} label='Anotações' multiline rows={10} />
                                </>
                                :
                                acao === 'Finalizar' ?
                                    <>
                                        <TextFieldDefault id='solucao' name='solucao' autoFocus={true} onChange={handleChange} value={sac.solucao} label='Solução' multiline rows={5} />
                                        <SelectAssuntos onChange={handleChange} value={sac.assunto} label='Assunto' />
                                        {getSetor() === 'AN' || getSetor() === 'CE' ?
                                            <TextFieldDefault id='versao' name='versao' onChange={handleChange} value={sac.versao} label='Versão' />
                                            : ''
                                        }
                                        {permRender('FD',
                                            <Box pl={1}>
                                                <StackLeft>
                                                    <TitleGrupos title='Finalizar direto: ' />
                                                    <Switch id='direto' name='direto' size='small' value={sac.direto} onChange={handleChange} style={{ color: ` ${sac.direto ? 'green' : ''}` }} />
                                                </StackLeft>
                                            </Box>)
                                        }
                                    </>
                                    :
                                    acao === 'Enviar' ?
                                        <>
                                            <SelectSetores onChange={handleChange} value={sac.tipo} label='Enviar para' />
                                        </>

                                        : ''}

            {acao === '' ? '' :
                <Stack direction='row' spacing={1} justifyContent='flex-end' paddingRight={1} paddingBottom={1} paddingTop={1}>
                    <ButtonIcon icon={<Send />} caption={acao} onClick={() => salvar()} color='green' />
                    <ButtonCancelar />
                </Stack>}
        </>
    );
}
