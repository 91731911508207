import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import useClientes from '../../hooks/useClientes';
import Autocomplete from '@mui/material/Autocomplete';
import useTipos from '../../hooks/useTipos';
import useAssuntos from '../../hooks/useAssuntos';
import useUsuarios from '../../hooks/useUsuarios';
import { orderList } from '../../utils/geral';
import { getSetor } from '../../utils/sac';
import useTag from '../../hooks/useTag';

///PADRÃO ************************************************************************************************************************************************************************************************************
function SelectDefault(props) {
    return (
        <Box sx={{ minWidth: 120, p: props.p ? props.p : 1 }} >
            <FormControl fullWidth variant='standard'>
                <InputLabel id={`InputLabel-${props.id}`} >{props.label}</InputLabel>
                <Select
                    labelId={`InputLabel-${props.id}`}
                    id={props.id}
                    name={props.id}
                    value={props.value}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    size={props.size}
                    label={props.label}
                >
                    {props.children}
                </Select>
            </FormControl>
        </Box>
    );
}

///SETORES ************************************************************************************************************************************************************************************************************
export function SelectSetores(props) {
    const { listTipos, loading } = useTipos();

    let lista = orderList(listTipos, 'nome');

    function handleSetor(setor) {
        return <MenuItem key={setor.codigo} value={setor.codigo}>{setor.nome}</MenuItem>
    }
    return (
        loading && (getSetor() === null) ? '' :
            <SelectDefault id='tipo' name={'tipo'} label='Setores' onChange={props.onChange} value={props.value} disabled={props.disabled} >
                {lista.map(handleSetor)}
            </SelectDefault>
    );
}

///USUARIOS ************************************************************************************************************************************************************************************************************
export function SelectUsuarios(props) {
    const { listUsuarios, loading } = useUsuarios();

    if (loading) return <></>
    return (
        <Box sx={{ minWidth: 120, p: 1 }}>
            <Autocomplete
                options={orderList(listUsuarios, "login")}
                getOptionLabel={(option) => option.login + ` - [${option.codigo}]`}
                id="pessoa"
                name="pessoa"
                value={props.value}
                onChange={props.onChange}
                disabled={props.disabled}
                renderInput={(params) => (
                    <TextField {...params} label="Usuários" variant="standard" />
                )}
                isOptionEqualToValue={(option, value) => option.codigo + '' === value.codigo + ''}
            />
        </Box>
    );
}

///ASSUNTOS ************************************************************************************************************************************************************************************************************
export function SelectAssuntos(props) {
    const { listAssuntos, loading } = useAssuntos(props.externo);

    function handleAssuntos(assunto) {
        return <MenuItem key={assunto.codigo} value={assunto.codigo}>{assunto.nome}</MenuItem>
    }

    return (
        loading ? '' :
            <SelectDefault id="assunto" name="assunto" onChange={props.onChange} value={props.value} label='Assuntos' disabled={props.disabled}>
                {orderList(listAssuntos, "nome").map(handleAssuntos)}
            </SelectDefault>
    );
}

///PRIORIDADE ************************************************************************************************************************************************************************************************************
export function SelectPrioridade(props) {
    return (
        <SelectDefault id="prioridade" name="prioridade" onChange={props.onChange} value={props.value} label='Prioridades' disabled={props.disabled}>
            <MenuItem value={2}>BAIXO</MenuItem>
            <MenuItem value={1}>MÉDIO</MenuItem>
            <MenuItem value={0}>ALTO</MenuItem>
        </SelectDefault>
    );
}

///TAMANHO DA FONTE ************************************************************************************************************************************************************************************************************
export function SelectFontSize(props) {
    const lista = props.lista;

    function handleSizes(size, s) {
        // return <MenuItem key={s} value={size}>{size}</MenuItem>
    }

    return (
        <SelectDefault id="fontesize" name="fontesize" onChange={props.onChange} value={props.value} label='FontSize' disabled={props.disabled}>
            {lista ? lista.map(handleSizes) : ''}
        </SelectDefault>
    );
}

///TIPO DATA ************************************************************************************************************************************************************************************************************
export function SelectTipoData(props) {
    return (
        <SelectDefault id="tipoData" name="tipoData" onChange={props.onChange} value={props.value} label='Tipo de Data' disabled={props.disabled}>
            <MenuItem value={'F'}>Finalização</MenuItem>
            <MenuItem value={'A'}>Abertura</MenuItem>
        </SelectDefault>
    );
}

///TIPO FAQ ************************************************************************************************************************************************************************************************************
export function SelectTipoFaq(props) {
    return (
        <SelectDefault id="tipo" name="tipo" onChange={props.onChange} value={props.value} label='Tipo de FAQ' disabled={props.disabled} >
            <MenuItem value={'D'}>Documentação</MenuItem>
            <MenuItem value={'V'}>Versionamento</MenuItem>
        </SelectDefault>
    );
}

///SOFTWARE ************************************************************************************************************************************************************************************************************
export function SelectSoftware(props) {
    return (
        <SelectDefault id="software" name="software" onChange={props.onChange} value={props.value} label='Software' disabled={props.disabled}>
            <MenuItem value={'REDSIS 4.0.0'}>REDSIS 4.0.0</MenuItem>
            <MenuItem value={'REDSIS 4.1.0'}>REDSIS 4.1.0</MenuItem>
            <MenuItem value={'MOBILE REDROCHAS'}>MOBILE REDROCHAS</MenuItem>
            <MenuItem value={'MOBILE REDFIN'}>MOBILE REDFIN</MenuItem>
            <MenuItem value={'MOBILE REDPEDIDO'}>MOBILE REDPEDIDO</MenuItem>
            <MenuItem value={'REDSIS WEBROCHAS'}>REDSIS WEBROCHAS</MenuItem>
            <MenuItem value={'SAC'}>SAC</MenuItem>
            <MenuItem value={'REDAPI'}>REDAPI</MenuItem>
        </SelectDefault>
    );
}

///CLIENTES ************************************************************************************************************************************************************************************************************
export function SelectClientes(props) {
    const { clientes, loading } = useClientes();

    if (loading) return <></>

    return (
        <Box sx={{ minWidth: 120, p: 1 }}>
            <Autocomplete
                options={orderList(clientes, "nome")}
                getOptionLabel={(option) => option.nome + `- ${option.cnpjcpf} - [${option.codigo}]`}
                id="pessoa"
                name="pessoa"
                value={props.value}
                onChange={props.onChange}
                disabled={props.disabled}
                renderInput={(params) => (
                    <TextField {...params} label="Clientes" variant="standard" />
                )}
                isOptionEqualToValue={(option, value) => option.codigo === value.codigo}
            />
        </Box>
    );
}

///TAGS ************************************************************************************************************************************************************************************************************
export function SelectTags(props) {
    const { getTags, listTags, loading } = useTag();

    React.useEffect(() => {
        getTags();
    }, [])

    if (loading) return <></>

    function handleTags(tag, t) {
        return <MenuItem key={t} value={tag.nome}>{tag.nome}</MenuItem>
    }

    return (
        loading ? '' :
            <SelectDefault id="tag" name="tag" onChange={props.onChange} value={props.value} label='Tag' disabled={props.disabled}>
                {orderList(listTags, "nome").map(handleTags)}
            </SelectDefault>
    );
}

// PESQUISAR ************************************************************************************************************************************************************************************************************
export function SelectPesquisa(props) {
    return (
        <SelectDefault id="filtro" name="filtro" onChange={props.onChange} value={props.value} label='Filtro' disabled={props.disabled} size={props.size} style={props.style} p={props.p}>
            <MenuItem value={'Agendado'}>Agendado</MenuItem>
            <MenuItem value={'Assunto'}>Assunto</MenuItem>
            <MenuItem value={'Atendente'}>Atendente</MenuItem>
            <MenuItem value={'Cliente'}>Cliente</MenuItem>
            <MenuItem value={'Codigo'}>Codigo</MenuItem>
            <MenuItem value={'Conteudo'}>Conteudo</MenuItem>
            <MenuItem value={'Direcionado'}>Direcionado</MenuItem>
            <MenuItem value={'Referencia'}>Referencia</MenuItem>
            <MenuItem value={'Software'}>Software</MenuItem>
            <MenuItem value={'Tag'}>Tag</MenuItem>
            <MenuItem value={'Versao'}>Versão</MenuItem>
        </SelectDefault>
    );
}


// TIPO ************************************************************************************************************************************************************************************************************
export function SelectMenuFAQ(props) {
    return (
        <SelectDefault id="menuFaq" name="menuFaq" onChange={props.onChange} value={props.value} label='Selectione menu a ser modificado' disabled={props.disabled}>
            <MenuItem value='doc'>Documentação</MenuItem>
            <MenuItem value='ver'>Versionamento</MenuItem>
        </SelectDefault>
    );
}