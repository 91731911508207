import { useState, useEffect, useContext } from "react";
import axios from "../services/api";
import useToken from "./useToken";
import { GeralContext } from "../context";
import { listaLength } from "../utils/string";


const useUsuarios = () => {
    const { token } = useToken();
    const [listUsuarios, setListUsuarios] = useState([]);
    const [loading, setLoading] = useState(true);
    const { listaUsers, toggleListaUsers } = useContext(GeralContext);

    var config = {
        headers: {
            authorization: token
        }
    };

    //#region RETORNA LISTA DE USUARIOS ******************************************************************************************************************************************************************************************************
    const getListUsuarios = async () => {
        if (listaLength(listaUsers) === 0) {
            try {
                const response = await axios.get('/usuarios', config);
                setListUsuarios(response.data.result);
                toggleListaUsers(response.data.result)
            } catch (error) {
                console.log(error);
            }
        } else {
            setListUsuarios(listaUsers);
        }
        setLoading(false);
    }
    //#endregion *********************************************************************************************************************************************************************************************************************************

    useEffect(() => {
        getListUsuarios();
        // eslint-disable-next-line
    }, [])

    return { getListUsuarios, listUsuarios, loading };
}

export default useUsuarios;

