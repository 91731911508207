export const iniAtendimento = {
    codigo: undefined,
    tipo: '',
    pessoa: null,
    assunto: '',
    codassunto: '',
    prioridade: 2,
    usuario: '',
    contato: '',
    conteudo: '',
    software: 'REDSIS 4.1.0',
    conteudos: [],
    caminhobd: ''
};

export const iniRastreios = {
    codigo: 0,
    descricao: undefined,
    master: 0
}

export const iniFaq = {
    codigo: 0,
    titulo: '',
    descricao: '',
    tipo: '',
    rastreio: 0
};

export const iniPesquisa = { filtro: "Cliente", valor: "" };

export const iniConteudos = [{ codigo: 0, titulo: '', conteudo: '' }];

export const iniMensagem = { mensagem: '', severity: '' };

export const iniAnexos = [{
    nome: "",
    tamanho: 0,
    data: ""
}]

export const iniMensagemFinal = { tag: '', solucao: '', versao: '' }

export const iniMovimento = { codigo: 0, nome: '', sigla: 'AN', ordem: 0 }

export const iniFormat = [{ bold: false }, { italic: false }, { underline: false }, { strikeThrough: false }, { insertOrderedList: false }, { insertUnorderedList: false }];

export const iniMenuFAQ = { id: 0,  tipo: "menu", nome: "Menu",  pastas: [{ id: 1, idMaster: 0, tipo: "doc", nome: "Documentos", pastas: [] }, { id: 2, idMaster: 0, tipo: "ver", nome: "Versionamento", pastas: [] }] };