import * as React from 'react';
import { Box, Grid, ListItemText } from '@mui/material';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { StackJustify } from '../../stack';
import { GridCenter } from '../../grid';
import { InfMenu, MenuAtendimento, MenuItemPrincipal } from '../components/menu';
import { TitleSmall, SubTitleSmall, TextConteudo } from '../../typography';
import { getPerm, getUser } from '../../../utils/user';
import { formatDateTime } from '../../../utils/date';
import { getThemeContraste } from '../../../utils/theme';
import { SacContext } from '../../../context';

export function HeaderSac(props) {
    const [idFocus, setIdFocus] = React.useState(0);
    const { toggleCodigo, sac } = React.useContext(SacContext);
    const atendimento = props.atendimento ? props.atendimento : sac;
    const disabledDestaque = props.disabledDestaque ? props.disabledDestaque : false

    return (<Box id={atendimento.codigo + '-' + atendimento.tipo}
        aria-labelledby={atendimento.codigo + '-' + atendimento.tipo}
        onMouseOver={() => setIdFocus(atendimento.id)} onMouseOut={() => setIdFocus(0)}
        style={{ width: '100%', padding: '5px' }}>
        <span onClick={((getPerm('VI') && atendimento.status === 'A') || getPerm('VI')) && !disabledDestaque ? () => toggleCodigo(atendimento.codigo) : function () { }} style={{ cursor: 'pointer' }}>
            <StackJustify>
                <TitleSmall title={props.codigo === atendimento.codigo ?
                    <>{atendimento.pessoa}</> :
                    atendimento.pessoa
                } />{atendimento.vip === 1 ? <StarOutlinedIcon fontSize='small' color={getThemeContraste()} /> : ''}
            </StackJustify>
        </span>
        <GridCenter>
            <Grid item xs={12} md={8} onClick={((getPerm('VI') && atendimento.status === 'A') || getPerm('VI')) && !disabledDestaque ? () => toggleCodigo(atendimento.codigo) : function () { }} style={{ cursor: 'pointer' }} >
                <GridCenter>
                    <Grid item xs={12} md={6}>
                        <SubTitleSmall subTitle={atendimento.assunto} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SubTitleSmall subTitle={atendimento.software} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SubTitleSmall subTitle={formatDateTime(atendimento.status === 'A' ? atendimento.data_atendimento : atendimento.status === 'P' ? atendimento.data_abertura : atendimento.data_abertura)} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SubTitleSmall subTitle={atendimento.atendpref === getUser().nome && atendimento.status === 'A' ? '' : atendimento.atendpref} />
                    </Grid>
                </GridCenter>
            </Grid>
            <Grid item xs={12} md={4} style={{ height: '50px' }}>
                {props.showMenuGeral ?
                    <StackJustify>
                        <InfMenu atendimento={atendimento} />
                        {props.fixedMenu || idFocus === props.id || sac.codigo === atendimento.codigo?
                            <StackJustify>
                                <MenuItemPrincipal
                                    showMenu={props.showMenu}
                                    atendimento={atendimento}
                                />
                                <MenuAtendimento atendimento={atendimento} />
                            </StackJustify>
                            : ''}
                    </StackJustify>
                    : ''}
            </Grid>
        </GridCenter>
    </Box >
    )
}

export function HeaderSacTC(props) {
    const { toggleCodigo, sac } = React.useContext(SacContext);
    const atendimento = props.atendimento ? props.atendimento : sac;
    const disabledDestaque = props.disabledDestaque ? props.disabledDestaque : false

    function listDescr(conteudo, c) {
        return <ListItemText key={c}
            primary={<TextConteudo text={conteudo}
            />}
        />
    }

    return (<Box id={atendimento.codigo + '-' + atendimento.tipo}
        aria-labelledby={atendimento.codigo + '-' + atendimento.tipo}
        style={{ width: '250px', height: '100px' }}>
        <span onClick={((getPerm('VI') && atendimento.status === 'A') || getPerm('VI')) && !disabledDestaque ? () => toggleCodigo(atendimento.codigo) : function () { }} style={{ cursor: 'pointer' }}>
            <StackJustify>
            </StackJustify>
        </span>
        <div style={{ maxHeight: '90px', position: 'sticky' }}>
            {atendimento.conteudo.split('\\n').map(listDescr)}
        </div>
        <SubTitleSmall subTitle={formatDateTime(atendimento.status === 'A' ? atendimento.data_atendimento : atendimento.status === 'P' ? atendimento.data_abertura : atendimento.data_abertura)} />
    </Box >
    )
}