import { useContext, useState } from "react";
import axios from "../services/api";
import useToken from "./useToken";
import { GeralContext } from "../context";
import { iniFaq } from "../utils/constructor";
import { delSession, getSession, setSession } from "../utils/storage";

const useFaq = () => {
    const { token } = useToken();
    const [listFaqApro, setListFaqApro] = useState([]);
    const [listFaqPend, setListFaqPend] = useState([]);
    const [faqMenu, setFaqMenu] = useState('');
    const { toggleMensagem } = useContext(GeralContext);
    const [faqEdit, setFaqEdit] = useState(iniFaq);
    const [loadingFAQ, setloadingFAQ] = useState(true);
    const [faq, setFaq] = useState(iniFaq);


    var config = {
        headers: {
            'Authorization': token
        },
    };

    const getFaqAprovados = async () => {
        const response = await axios.get('/faq', config)
        setListFaqApro(response.data.result);
    }

    const getFaqPendentes = async () => {
        const response = await axios.get('/faq/pendentes', config)
        setListFaqPend(response.data.result);
    }

    const getFaq = async (codigo) => {
        setloadingFAQ(true);
        const response = await axios.get(`faq/${codigo}`, config);
        setFaqEdit(response.data.result[0] ? response.data.result[0] : iniFaq);
        setFaq(response.data.result[0] ? response.data.result[0] : iniFaq);
        setloadingFAQ(false);
    }

    const postFaq = async (paramns) => {
        if (paramns.codigo === 0) {
            const data = {
                "titulo": paramns.titulo,
                "descricao": paramns.descricao,
                "tipo": paramns.tipo,
                "rastreio": paramns.rastreio
            }

            const response = await axios({
                method: 'post',
                url: '/faq',
                headers: {
                    authorization: token
                },
                data: data,
            });
            toggleMensagem(response.data.result, 'success');
        } else {
            const data = {
                "codigo": paramns.codigo,
                "titulo": paramns.titulo,
                "descricao": paramns.descricao,
                "tipo": paramns.tipo,
                "rastreio": paramns.rastreio
            }

            const response = await axios({
                method: 'put',
                url: `/faq/${paramns.codigo}`,
                headers: {
                    authorization: token
                },
                data: data,
            });
            toggleMensagem(response.data.result, 'success');
        }
        window.location.href = '/faq/pend';
    }

    const aprovFaq = async (codigo) => {
        try {
            const response = await axios({
                method: 'put',
                url: `/faq/${codigo}/aprovar`,
                headers: {
                    authorization: token
                }
            });
            toggleMensagem(response.data.result, 'success');
        } catch (error) {
            toggleMensagem("Não foi possível aprovar FAQ.", 'error');
        }
    }

    const deleteFaq = async (codigo) => {
        try {
            const response = await axios({
                method: 'delete',
                url: `/faq/${codigo}`,
                headers: {
                    authorization: token
                }
            });
            toggleMensagem(response.data.result, 'success');
        } catch (error) {
            toggleMensagem("Não foi possível excluir FAQ.", 'error');
        }
    }


    const getFAQMenu = async () => {
        setloadingFAQ(true);
        if (getSession('menuFaq')) {
        setFaqMenu(JSON.parse(getSession('menuFaq')));
        } else {
        const response = await axios.get('/faq/menu', config)
        setFaqMenu(response.data.result);
        setSession('menuFaq', JSON.stringify(response.data.result))
        }
        setloadingFAQ(false);
    }

    const postFAQMenu = (json) => {
        fetch('https://server.redsis.com.br:8085/faq/menu', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(
                {
                    "result": json
                }
            )
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erro ao enviar o arquivo.');
                }
                toggleMensagem("Arquivo enviado com sucesso!", 'success');
                delSession('menuFaq');
            })
            .catch(error => {
                toggleMensagem("Não foi possível atualizar menu FAQ.", 'error');
            });
    }

    return { getFaqPendentes, getFaqAprovados, getFAQMenu, postFAQMenu, faqMenu, listFaqApro, listFaqPend, postFaq, getFaq, faqEdit, loadingFAQ, aprovFaq, deleteFaq, faq }
}
export default useFaq;