import * as React from 'react';
import { ButtonConcluido } from '../../components/button';
import { SelectAssuntos, SelectSoftware } from '../../components/select';
import { TextFieldButton, TextFieldDefault } from '../../components/textfield';
import { SearchRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import useSac from '../../hooks/useSac';
import { ContainerPages } from '../../components/container';
import { useParams } from 'react-router-dom';
import useClientesExterno from '../../hooks/useClientesExterno';
import useToken from '../../hooks/useToken';
import { formatCnpjCpf } from '../../utils/string';
import { AlertDialog } from '../../components/dialog';


export default function FormExterno() {
    const { cnpjcpf } = useParams();
    const { getTokenExterno, auth } = useToken();
    const { cliente, loading, getCliente, } = useClientesExterno(cnpjcpf);
    let defaultSac = {
        codigo: undefined, tipo: 'AT', assunto: '', prioridade: '', conteudo: '', software: '', pessoa: { nome: '', cnpjcpf: cnpjcpf }, pesquisa: '', origem: 'EX'
    }
    const [sac, setSac] = React.useState(defaultSac);
    const [open, setOpen] = React.useState(false);
    const [bloq, setBloq] = React.useState(false);
    const { postSac, msgExterno } = useSac();
    let active = sac.pessoa === null || sac.assunto === '' || sac.prioridade === '' || sac.contato === '' || sac.conteudo === '';


    React.useEffect(() => {
        getTokenExterno();
        setSac(defaultSac);
        // eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        if (msgExterno.protocolo)
            setOpen(true);
    }, [msgExterno])

    React.useEffect(() => {
        setTimeout(() => {
            if (cnpjcpf)
                getCliente(cnpjcpf)
            if (cliente[0]) {
                setSac((prevalue) => {
                    return {
                        ...prevalue,
                        pessoa: { nome: cliente[0].nome, cnpjcpf: cliente[0].cnpjcpf, codigo: cliente[0].codigo }
                    }
                })
            }
        }, 500);
        // eslint-disable-next-line
    }, [loading, auth])

    //Sac Criar/Editar **********************************************************************************************************************
    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        setSac((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        })
        if (name === 'assunto') {
            let p = JSON.parse(sessionStorage.getItem('assuntos'));
            for (let i = 0; i < p.length; i++) {
                if (p[i].codigo === value) {
                    setSac((prevalue) => {
                        return {
                            ...prevalue,
                            prioridade: p[i].prioridade
                        }
                    })
                }
            }
        }
    }

    function salvar() {
        setBloq(true);
        postSac(sac);
    }

    function consultarCliente(cnpj) {
        if (cnpj) {
            getCliente(cnpj);
            if (!loading) {
                if (cliente[0]) {
                    setSac((prevalue) => {
                        return {
                            ...prevalue,
                            pessoa: { nome: cliente[0].nome, cnpjcpf: cliente[0].cnpjcpf, codigo: cliente[0].codigo }
                        }
                    })
                } else {
                    alert('CNPJ não encontrado! Verifique e tente novamente.');
                    setSac((prevalue) => {
                        return {
                            ...prevalue,
                            pesquisa: ''
                        }
                    })
                }
            }
        }
    }

    return (
        <ContainerPages navBarSimple={true} ativarLogo={true} activeAuxBar={true} title='Novo atendimento' ocultarBotoes={true}>
            {auth ?
                <Box >
                    {!loading ?
                        <TextFieldDefault id='pessoa' name='pessoa' onChange={handleChange} disabled={true} value={formatCnpjCpf(sac.pessoa.cnpjcpf) + ' - ' + sac.pessoa.nome} label='Empresa' />
                        :
                        <TextFieldButton id='pesquisa' name='pesquisa' value={sac.pesquisa} onChange={handleChange} onClick={() => consultarCliente(document.getElementById('pesquisa').value)} label='Informe o CNPJ' icon={<SearchRounded />} />
                    }
                    <SelectAssuntos id='assunto' name='assunto' onChange={handleChange} value={sac.assunto} label='Assunto' externo={true} />
                    <SelectSoftware id='software' name='software' onChange={handleChange} value={sac.software} defaultValue={'REDSIS 4.0.0'} label='Software' />
                    <TextFieldDefault id='contato' name='contato' onChange={handleChange} value={sac.contato} label='Contato' />
                    <TextFieldDefault id='conteudo' name='conteudo' onChange={handleChange} value={sac.conteudo} label='Conteudo' multiline rows={5} />

                    <Box p={2} style={{ textAlign: 'end' }}>
                        <ButtonConcluido disabled={active || bloq} onClick={() => salvar()} color='green' />
                    </Box>
                </Box>
                : ''}
            {open ? <AlertDialog open={open} protocolo={msgExterno.protocolo} tipo={msgExterno.tipo} /> : ''}
        </ContainerPages >
    )
}
