import React from "react";
import { ContainerPages } from "../../components/container";
import { getThemeContraste, heightScreen } from "../../utils/theme";
import { Typography } from "@mui/material";

export default function NotFound() {
    return (<ContainerPages>
        <img src='../app-assets/img/logo/Logo.svg' alt='logo redsis' style={{ height: heightScreen(-200), width: '100%' }} />
        <Typography variant="h6" gutterBottom style={{ textAlign: 'center', color: getThemeContraste() }}>
            404 - Pagina não encontrada
        </Typography>
    </ContainerPages >
    );
}