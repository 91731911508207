import { useState, useEffect } from "react";
import axios from "../services/api";
import useToken from "./useToken";
import { getSession, setSession } from "../utils/storage";


const useAssuntos = (externo) => {
    const { token } = useToken();
    const [listAssuntos, setListAssuntos] = useState([]);
    const [loading, setLoading] = useState(true);

    var config = {
        headers: {
            authorization: token
        }
    };

    //#region RETORNA LISTA DE SETORES ******************************************************************************************************************************************************************************************************
    const getlistAssuntos = async () => {
        let ex = externo ? externo : false;
        if (getSession('assuntos') === null | externo) {
            try {
                const response = await axios.get(`/atendimentos/assuntos?externo=${ex}`, config);
                setSession('assuntos', JSON.stringify(response.data.result));
                setListAssuntos(response.data.result);
                if (!externo)
                    setSession('assuntos', JSON.stringify(response.data.result));
            } catch (error) {
                console.log(error);
            }

        } else {
            setListAssuntos(JSON.parse(getSession('assuntos')));
        }
        setLoading(false);
    }
    //#endregion *********************************************************************************************************************************************************************************************************************************


    useEffect(() => {
        getlistAssuntos();
        // eslint-disable-next-line
    }, [])

    return { listAssuntos, loading };
}

export default useAssuntos;

