import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { SelectPesquisa } from '../select';
import { TextFieldButton2 } from '../textfield';
import { GeralContext, SacContext } from '../../context';
import { StackRight } from '../stack';
import { BorderBottom } from '@mui/icons-material';

export function SearchBar(props) {
    const { pesquisar, ativarPesquisa, togglePesquisar, clearPesquisar, clickPesquisar } = React.useContext(GeralContext);
    const { toggleCodigo } = React.useContext(SacContext);

    if (ativarPesquisa || props.pesqFinalizar === true) {
        return (
            <Box pl={2} pr={2} style={{ width: '100%' }} >
                < form onSubmit={clickPesquisar} >
                    <Grid container>
                        <Grid item xs={12} md={2}>
                            <SelectPesquisa value={pesquisar.filtro} onChange={togglePesquisar} />
                        </Grid>
                        <Grid item xs={12} md={window.innerWidth < 768 ? 10 : 5} pt={1}>
                            <TextFieldButton2
                                id='valor'
                                value={pesquisar.valor}
                                onClickCheck={() => toggleCodigo(0)}
                                onClickCancel={clearPesquisar}
                                onChange={togglePesquisar}
                                label='Pesquisar'
                                typeButton='submit' />
                        </Grid>
                    </Grid>
                </form >
            </Box>
        );
    } else {
        return '';
    }
}

export function SearchNavBar(props) {
    const { pesquisar, ativarPesquisa, togglePesquisar, clearPesquisar, clickPesquisar } = React.useContext(GeralContext);
    const { toggleCodigo } = React.useContext(SacContext);

    if (ativarPesquisa || props.pesqFinalizar === true) {
        return (
            <form onSubmit={clickPesquisar} >
                <Box style={{background: '#343944', borderRadius: '10px', display: 'flex'}}>
                    <SelectPesquisa p={-1} value={pesquisar.filtro} onChange={togglePesquisar} size='small'  />
                    <TextFieldButton2
                        id='valor'
                        value={pesquisar.valor}
                        onClickCheck={() => toggleCodigo(0)}
                        onClickCancel={clearPesquisar}
                        onChange={togglePesquisar}
                        size='small'
                        label='Pesquisar'
                        typeButton='submit' 
                        />
                </Box>
            </form >
        );
    } else {
        return '';
    }
}