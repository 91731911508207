import { useState } from "react";
import axios from "../services/api";

const useConfig = () => {
  const token = 'Bearer ' + sessionStorage.getItem('token_sac');
  const [igFila, setIgFila] = useState(false);

  const getIgFilas = async () => {
    var config = {
      headers: {
        'Authorization': token
      },
    };
    const response = await axios.get('/config/ignorarfila', config)
    setIgFila(response.data.result);
  }

  const setIgFilas = async (fila) => {
    var config = {
      headers: {
        'Authorization': token
      },
      params: {
        ignorarfila: fila
      }
    };
    const response = await axios.get('/config/ignorarfila', config)
    setIgFila(response.data.result);
  }

  return { getIgFilas, setIgFilas, igFila }
}
export default useConfig;