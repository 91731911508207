import React, { useContext, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import TextField from '@mui/material/TextField';
import useToken from "../../hooks/useToken";
import { Avatar, Paper, Typography } from "@mui/material";
import SnackbarAlert from "../../components/snackbar";
import { GeralContext } from "../../context";
import { SimpleContainerRedsis } from "../../components/container";

export default function Login() {
    const { mensagem } = useContext(GeralContext);
    const { getToken } = useToken();
    const [data, setData] = useState({
        usuario: '',
        senha: '',
        servico: 'sac'
    })

    useEffect(() => {
        sessionStorage.clear('');
        // eslint-disable-next-line 
    }, [])

    async function submit(e) {
        e.preventDefault();
        getToken(data);
    }

    function handleData(e) {
        const { name, value } = e.target;
        setData({ ...data, [name]: value })
    }

    return (
        <SimpleContainerRedsis>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ vertcalAlign: 'middle', height: '100%' }}
            >
                {window.innerWidth >= 768 ?
                    <Box>
                        <img src='../app-assets/img/logo/Logo.svg' alt='logo redsis' style={{ height: '100vh', width: '100%' }} />
                    </Box>
                    : ''}
                <Paper elevation={2} sx={{ background: 'inherit', textAlign: 'center', width: '300px' }}>
                    <Box p={3}>
                        <form onSubmit={(e) => submit(e)}>
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                sx={{ vertcalAlign: 'middle', height: '60vh' }}
                            >
                                {window.innerWidth < 768 ? <Avatar alt="Logo Redsis" src="../app-assets/img/logo/Logo.svg" sx={{ width: 256, height: 256 }} /> : ''}
                                <Typography variant="subtitle1"><b>Bem-vindo à </b><b style={{ color: '#ed3237' }}>REDSIS</b></Typography>
                                <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }} >
                                    <PersonOutlineOutlinedIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                    <TextField autoFocus name="usuario" label="Login" variant="standard" fullWidth autoComplete="off" onChange={(e) => handleData(e)} />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }} pb={2} >
                                    <PasswordOutlinedIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                    <TextField name="senha" label="Senha" variant="standard" type="password" fullWidth autoComplete="off" onChange={(e) => handleData(e)} />
                                </Box>
                                <Button type="submit" fullWidth variant='contained' color="green" >
                                    Login
                                </Button>
                            </Stack>
                        </form>
                    </Box>
                </Paper>
            </Stack >
            <SnackbarAlert msg={mensagem} />
        </SimpleContainerRedsis >
    );
}