import React, { useEffect, useRef, useState } from 'react';
import { TextFieldDefault, TextFieldResizes } from '../../components/textfield';
import { iniFaq, iniFormat } from '../../utils/constructor';
import { ContainerPages } from '../../components/container';
import useRastreios from '../../hooks/useRastreios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, ButtonGroup, Divider, MenuItem, Select, Stack } from '@mui/material';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import { getTheme, getThemeContraste, heightDefault } from '../../utils/theme';
import { TextDescricaoLarge, Title } from '../../components/typography';
import { BoldIcon, Check, ItalicIcon, ListIcon, OrderListIcon, StrikethroughIcon, UnderlinedIcon, ImageIcon, LinkOn, IconSave, EditIcon, ResizeIcon, RelationIcon, Delete } from '../../components/icons';
import { ButtonConcluido, ButtonLinkIcon, ButtonToolTip } from '../../components/button';
import useFaq from '../../hooks/useFaq';
import { SelectTipoFaq } from '../../components/select';
import { getSession, setSession } from '../../utils/storage';
import { useParams } from 'react-router-dom';
import { filtrarLista } from '../../utils/geral';
import { listaLength } from '../../utils/string';
import { DialogResizeImage, FAQDialogRef, FAQDialogRefLink } from "../../components/dialog";
import { BoxFaq } from '../../components/box';
import { StackJustify } from '../../components/stack';

function Faq() {
    const editableDivRef = useRef(null);
    const [idFocus, setIdFocus] = React.useState(0);
    const [faq, setFaq] = useState(iniFaq);
    const { postFaq, getFaq, faqEdit, listFaqApro, getFaqAprovados, deleteFaq } = useFaq();
    const { getRastreios, rastreios, loadingRastreio } = useRastreios();
    const [format, setFormat] = useState(iniFormat);
    const { codigo } = useParams();
    const [width, setWidth] = useState(200);
    const [height, setHeight] = useState(200);
    const [aspectRatio, setAspectRatio] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showDialogRef, setShowDialogRef] = useState(false);
    const [showDialogRefLink, setShowDialogRefLink] = useState(false);
    const [showDialogResize, setShowDialogResize] = useState(false);
    const [linkFaq, setLinkFaq] = useState({ link: '', descricao: '' });

    useEffect(() => {
        getRastreios();
        if (getSession('tempFaq')) {
            setFaq(JSON.parse(getSession('tempFaq')))
            document.getElementById('textFaq').innerHTML = JSON.parse(getSession('tempFaq')).descricao;
        } else {
            if (codigo)
                getFaq(codigo);

            if (faqEdit.codigo > 0) {
                setFaq({
                    "codigo": faqEdit.codigo,
                    "titulo": faqEdit.titulo,
                    "descricao": faqEdit.descricao,
                    "tipo": faqEdit.tipo,
                    "rastreio": faqEdit.rastreio
                })
                document.getElementById('textFaq').innerHTML = getTheme() === 'light' ? faqEdit.descricao.replaceAll('(241, 241, 241)', '(51, 51, 51)')
                    : faqEdit.descricao.replaceAll('(51, 51, 51)', '(241, 241, 241)');
            }
        }
    }, [faqEdit.codigo])


    useEffect(() => {
        getFaqAprovados();
    }, [showDialogRef === true])

    const updateStatus = (index, key) => {
        const updatedItems = format.map((item, i) => {
            if (i === index) {
                return { ...item, [key]: !item[key] };
            }
            return item;
        });
        setFormat(updatedItems);
    };

    const toggleStyle = (command) => {
        document.execCommand(command, false, null);
        const index = format.findIndex(item => item.hasOwnProperty(command));
        updateStatus(index, command);
    };

    const fontSizeMap = {
        1: '14px',
        2: '16px',
        3: '18px',
        // 4: '24px',
        // 5: '36px',
        // 6: '48px',
    };

    React.useEffect(() => {
        let item = filtrarLista(rastreios, 'codigo', faq.rastreio);
        if (listaLength(item) > 0) {
            setFaq((prevalue) => {
                return {
                    ...prevalue,
                    rastreio: item[0]
                }
            })
        }
    }, [faq.rastreio])


    function ListarItens(item) {
        let child = rastreios.filter(it => it.master === item.codigo);
        return <TreeItem key={item.codigo} nodeId={item.codigo + ''} style={{ alignItems: 'center' }}
            label={<Stack direction='row' alignItems='center'>
                <Title title={item.descricao} />
                {idFocus === item.codigo ?
                    <ButtonToolTip title='Selecionar' color='green' icon={<Check color='blue' />} onClick={() => setRastreio(item)} />
                    : ''}
            </Stack>} onClick={() => setIdFocus(item.codigo)}
        >
            {child.length === 0 ? "" : child.map(ListarItens)}
        </TreeItem>
    }

    function setRastreio(item) {
        setFaq((prevalue) => {
            return {
                ...prevalue,
                rastreio: item
            }
        })
    }

    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        setFaq((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        })
    }

    const changeFontSize = (event) => {
        const fontSizeValue = event.target.value;
        document.execCommand('fontSize', false, fontSizeValue);
        const fontElements = editableDivRef.current.getElementsByTagName('font');
        for (let i = 0; i < fontElements.length; i++) {
            const fontElement = fontElements[i];
            if (fontElement.size === fontSizeValue) {
                fontElement.removeAttribute('size');
                fontElement.style.fontSize = fontSizeMap[fontSizeValue];
            }
        }
    };

    const makeLink = (targetLink) => {
        const textFaq = document.getElementById('textFaq');
        if (!textFaq) return;
        const link = document.createElement('a');
        const href = linkFaq.link;
        const descricao = linkFaq.descricao;
        if (!href || !descricao) return;

        link.href = href;
        link.target = targetLink ? targetLink : '_blank';
        link.textContent = descricao;
        link.style.cursor = 'pointer';
        link.style.color = getThemeContraste();

        link.addEventListener('click', (event) => {
            event.preventDefault();
            window.open(link.href, link.target);
        });

        textFaq.appendChild(link);

        setLinkFaq({ link: '', descricao: '' });
        setShowDialogRefLink(false);
        setShowDialogRef(false);
    };

    const handleSave = () => {
        sessionStorage.removeItem('tempFaq');
        postFaq({
            "codigo": faq.codigo,
            "titulo": faq.titulo,
            "descricao": document.getElementById('textFaq').innerHTML,
            "tipo": faq.tipo,
            "rastreio": faq.rastreio.codigo
        })
    }

    const handleSaveTemp = () => {
        setSession('tempFaq',
            JSON.stringify({
                "codigo": faq.codigo,
                "titulo": faq.titulo,
                "descricao": document.getElementById('textFaq').innerHTML,
                "tipo": faq.tipo,
                "rastreio": faq.rastreio
            })
        )
    }

    function trilhaRastreio() {
        let listaRastreio = [];
        listaRastreio.push(filtrarLista(rastreios, 'codigo', faq.rastreio.codigo)[0]);
        listaRastreio = listaRastreio.filter(function (element) {
            return element !== undefined;
        });
        for (let i = 0; i < listaLength(listaRastreio); i++) {
            if (listaRastreio[i].master > 0) {
                listaRastreio.push(filtrarLista(rastreios, 'codigo', listaRastreio[i].master)[0]);
            }
        }
        return listaRastreio.reverse();
    }

    function ListarRastreio(r) {
        return <TextDescricaoLarge key={r.codigo} text={r.master === 0 ? r.descricao + ' ' : ' > ' + r.descricao} />
    }

    function vincularFaq(item) {
        setLinkFaq({
            link: `http://${window.location.host}/faq/${item.codigo}`,
            descricao: item.titulo
        })
        makeLink('_self');
    }


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    insertImage(event.target.result, img.width, img.height);
                };
                img.src = event.target.result;
            };
            reader.readAsDataURL(file);
        }
    };

    const insertImage = (imageSrc, originalWidth, originalHeight) => {
        if (imageSrc && editableDivRef.current) {
            const img = document.createElement('img');
            img.src = imageSrc;
            img.style.width = `${originalWidth}px`;
            img.style.height = `${originalHeight}px`;
            img.style.border = "1px solid black";
            img.contentEditable = false;
            img.addEventListener('click', () => handleSelectImage(img));

            editableDivRef.current.appendChild(img);
            clearImageVariables();
        }
    };

    const clearImageVariables = () => {
        setWidth(200);
        setHeight(200);
        setAspectRatio(null);
        setSelectedImage(null);
    };



    const handleSelectImage = (img) => {
        setShowDialogResize(true);
        setSelectedImage(img);
        setWidth(parseInt(img.style.width));
        setHeight(parseInt(img.style.height));
        let proporcao = parseInt(img.style.width) / parseInt(img.style.height)
        setAspectRatio(proporcao)
    };

    const updateImageSize = () => {
        if (selectedImage) {
            selectedImage.style.width = `${width}px`;
            selectedImage.style.height = `${height}px`;
        }
        setShowDialogResize(false);
    };

    const handleWidthChange = (e) => {
        const newWidth = e.target.value;
        setWidth(newWidth);
        if (aspectRatio) {
            let alt = newWidth / aspectRatio;
            setHeight(alt.toFixed(3));
        }
    };

    const handleHeightChange = (e) => {
        const newHeight = e.target.value;
        setHeight(newHeight);
        setWidth(newHeight * aspectRatio)
        if (aspectRatio) {
            let larg = newHeight * aspectRatio;
            setWidth(larg.toFixed(3));
        }
    };

    const handleClose = () => {
        setShowDialogRef(false);
        setShowDialogRefLink(false);
        setShowDialogResize(false);
    };

    function ListarFaq(item) {
        return <BoxFaq key={item.codigo} onClick={() => vincularFaq(item)} faq={item} />
    }

    const handleChangeLink = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setLinkFaq((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        })
    }

    function excluirFaq(codigo) {
        deleteFaq(codigo);
    }

    return (
        <ContainerPages
            ativarLogo={true}
            activeAuxBar={true}
            title={faq.codigo > 0 ? 'Editar FAQ' : 'Novo FAQ'}
            toVoltar='/faq/pend'
        >
            <TextFieldDefault
                id='titulo'
                name='titulo'
                onChange={handleChange}
                value={faq.titulo}
                autoFocus={true}
                label='Titulo'
            />
            <SelectTipoFaq onChange={handleChange} value={faq.tipo} />

            <Box p={1}>
                {faq.rastreio.codigo > 0 && !loadingRastreio ? <>
                    <TextDescricaoLarge text='Rastreio' />
                    <Stack direction='row' alignItems='center'>
                        {trilhaRastreio().map(ListarRastreio)}
                        <ButtonToolTip
                            title='Editar'
                            color='blue'
                            icon={<EditIcon fontSize='18px' color='blue' />}
                            onClick={() => setFaq({ ...faq, rastreio: 0 })}
                        />
                    </Stack>
                </> : <>
                    <TreeView
                        aria-label="rastreios Faq"
                        defaultCollapseIcon={<ExpandMoreIcon color='iconColor' />}
                        defaultExpandIcon={<ChevronRightIcon color='iconColor' />}
                    >
                        <Box p={1}>
                            <TreeItem nodeId={'0'} label={<Title title='Rastreios' />}>
                                {loadingRastreio ? '' : rastreios.filter(it => it.master === 0).map(ListarItens)}
                            </TreeItem>
                        </Box>
                    </TreeView>
                </>}
            </Box>

            {/* ButtonGroup Flutuante */}
            <ButtonGroup>
                <ButtonToolTip icon={<BoldIcon />} onClick={() => toggleStyle('bold')} title='Negrito' />
                <ButtonToolTip icon={<ItalicIcon />} onClick={() => toggleStyle('italic')} title='Itálico' />
                <ButtonToolTip icon={<UnderlinedIcon />} onClick={() => toggleStyle('underline')} title='Sublinhado' />
                <ButtonToolTip icon={<StrikethroughIcon />} onClick={() => toggleStyle('strikeThrough')} title='Tachado' />
                <Select variant='standard' onChange={changeFontSize} defaultValue={1} size='small' >
                    {Object.entries(fontSizeMap).map(([key, value]) => (<MenuItem key={key} value={key}>{value}</MenuItem>))}
                </Select>
                <Divider orientation="vertical" variant="middle" flexItem />
                <ButtonToolTip icon={<OrderListIcon />} onClick={() => toggleStyle('insertOrderedList')} title='Lista Ordenada' />
                <ButtonToolTip icon={<ListIcon />} onClick={() => toggleStyle('insertUnorderedList')} title='Lista Não Ordenada' />
                <Divider orientation="vertical" variant="middle" flexItem />
                <ButtonToolTip icon={<LinkOn />} onClick={() => setShowDialogRefLink(true)} title='Link' />
                <ButtonToolTip title='Referênciar FAQ' icon={<RelationIcon />} onClick={() => setShowDialogRef(true)} />
                <Divider orientation="vertical" variant="middle" flexItem />
                <ButtonToolTip icon={<ImageIcon />} onClick={() => document.getElementById('imageInput').click()} title='Adicionar Imagens' />
                <Divider orientation="vertical" variant="middle" flexItem />
                <ButtonToolTip icon={<IconSave />} onClick={handleSaveTemp} title='Salvar Temporário' />
            </ButtonGroup>
            <input type="file" accept="image/*" onChange={handleImageChange} style={{ display: 'none' }} id="imageInput" />

            <Box p={1}
                id='textFaq'
                ref={editableDivRef}
                contentEditable={true}
                tabIndex={100}
                style={{
                    border: `1px solid ${getThemeContraste()}`,
                    color: getThemeContraste(),
                    padding: '10px',
                    minHeight: heightDefault() - 50,
                    marginTop: '10px',
                    fontSize: '14px',
                }}
            ></Box>

            <StackJustify>
                <ButtonLinkIcon to='/faq/pend' title='Excluir' icon={<Delete color='red' />} onClick={() => excluirFaq(faq.codigo)} />
                <ButtonConcluido onClick={handleSave} />
            </StackJustify>
            {showDialogRef ?
                <FAQDialogRef open={showDialogRef} onClose={handleClose}>
                    {listFaqApro.map(ListarFaq)}
                </FAQDialogRef>
                : ''}
            {showDialogRefLink ?
                <FAQDialogRefLink
                    valueDescr={linkFaq.descricao}
                    valueLink={linkFaq.link}
                    onChange={handleChangeLink}
                    open={showDialogRefLink} onClose={handleClose}
                    onClick={() => makeLink()}
                />
                : ''}
            {showDialogResize ?
                <DialogResizeImage
                    valueWidth={width}
                    valueHeight={height}
                    onChangeW={handleWidthChange}
                    onChangeH={handleHeightChange}
                    open={showDialogResize} onClose={handleClose}
                    onClick={updateImageSize}
                />
                : ''}
            <p />
        </ContainerPages>

    );
}

export default Faq;
