import * as React from 'react';
import { ContainerPages } from '../../components/container';
import { Box, List, ListItem, ListItemText, } from '@mui/material';
import { TextFieldDefault } from '../../components/textfield';
import { Add, Delete, HighLigth } from '../../components/icons';
import { TextConteudo } from '../../components/typography';
import { ButtonConcluido, ButtonIcon, ButtonToolTip } from '../../components/button';
import { orderList } from '../../utils/geral';
import useMovimento from '../../hooks/useMovimentos';
import { listaLength } from '../../utils/string';
import { SelectSetores } from '../../components/select';
import { StackRight } from '../../components/stack';
import { iniMovimento } from '../../utils/constructor';
import { Edit } from '@mui/icons-material';

export default function Movimentos() {
    const { listMovimento, deleteMovimento, getMovimentos, postMovimento, putMovimento } = useMovimento();
    const [openText, setOpenText] = React.useState(false);
    const [movimento, setMovimento] = React.useState(iniMovimento);
    const [atualizar, setAtualizar] = React.useState(true);

    React.useEffect(() => {
        getMovimentos(movimento.sigla);
        setAtualizar(false);
    }, [atualizar, movimento.sigla])

    const handleChange = (event) => {
        let { name, value } = event.target;
        name = name === 'tipo' ? 'sigla' : name;
        setMovimento((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        });
    }

    const cancelEdit = () => {
        setMovimento(iniMovimento);
        setOpenText(false);
    }

    const handleSubmit = async (e) => {
        if (movimento.codigo === 0) {
            await postMovimento(movimento);
            e.preventDefault();
        }
        else {
            await putMovimento(movimento);
        }
        setOpenText(false);
        setAtualizar(true)
    }


    function ListarMovimentos(mov, m) {
        return (<ListItem key={m} disableGutters onClick={() => setMovimento(mov)}
            style={{ cursor: 'pointer', borderBottom: mov.codigo === movimento.codigo ? '1px solid #ed3237' : 'inherit' }}
            secondaryAction={mov.codigo === movimento.codigo ?
                <>
                    <ButtonToolTip title='Editar' color='blue' icon={<Edit fontSize='18px' />} onClick={() => setOpenText(true)} />
                    <ButtonToolTip title='Excluir' color='red' icon={<Delete fontSize='18px' />} onClick={() => deleteMovimento(mov.codigo)} />
                </>
                : ''}
        >
            <ListItemText primary={<TextConteudo text={`${mov.nome}  -  ${mov.ordem}`} />} />
        </ListItem >
        );
    }

    function FormMovimento() {
        return <Box p={1}>
            <TextFieldDefault autoFocus name='nome' label='Nome' value={movimento.nome} onChange={handleChange} />
            <TextFieldDefault fullWidth name='ordem' label='Ordem' value={movimento.ordem} onChange={handleChange} />
            <SelectSetores name="sigla" value={movimento.sigla} onChange={handleChange} />
            <StackRight>
                <ButtonConcluido onClick={handleSubmit} />
                <ButtonIcon icon={<HighLigth color='white' />} caption='Cancelar' onClick={cancelEdit} color='red' style={{ color: 'white' }} />
            </StackRight>
        </Box>
    }

    function novoMov() {
        setMovimento(iniMovimento)
        setOpenText(true)
    }

    return <ContainerPages  ativarLogo={true} activeAuxBar={true} overflow={true} title='Movimentos' btnAux={<ButtonToolTip title='Novo movimento' color='green' icon={<Add />} onClick={() => novoMov()} />}>
        {openText ? FormMovimento() :
            <>
                <SelectSetores name="sigla" value={movimento.sigla} onChange={handleChange} />
                <Box p={1} pt={0}>
                    <List sx={{ bgcolor: 'inherit' }}>
                        {listaLength(listMovimento) > 0 ? orderList(listMovimento, "nome").map(ListarMovimentos) : ''}
                    </List >
                </Box>
            </>
        }
    </ContainerPages>
}