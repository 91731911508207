import React from 'react';
import { Grid } from '@mui/material';

export function GridCenter(props) {
    return (<Grid container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
        style={{padding: '0px'}}
    >
        {props.children}
    </Grid>
    );
}

export function GridLeft(props) {
    return (<Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
    >
        {props.children}
    </Grid>
    );
}