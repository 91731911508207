import * as React from 'react';
import useSac from '../../../hooks/useSac';
import { SubTitle, SubTitleSmall, TextConteudo, TextDescricao, TitleSmall } from '../../typography';
import { GridCenter } from '../../grid';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, ListItem, ListItemText, Stack } from '@mui/material';
import { permRender } from '../../../utils/user';
import { formatDateTime } from '../../../utils/date';
import { colorSac } from '../../../utils/sac';
import { ButtonToolTip } from '../../button';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined';
import { SacContext } from '../../../context';
import useConteudos from '../../../hooks/useConteudos';
import { StackJustify } from '../../stack';
import { formatCnpjCpf } from '../../../utils/string';

export default function SacResumo(props) {
    const atendimento = props.atendimento;
    const { putSac, sacVincular } = useSac();
    const { getConteudos, conteudos, loadingConteudos } = useConteudos();
    const [expanded, setExpanded] = React.useState(false);
    const { codigo } = React.useContext(SacContext);

    React.useEffect(() => {
        getConteudos(atendimento.codigo);
    }, [atendimento.codigo])

    function ListConteudos(conteudo) {
        return <ListItem style={{ background: 'inherit' }} key={conteudo.codigo}>
            <Stack direction='row' >
                <ArrowRightOutlinedIcon color='colorIcon'/>
                <Stack>
                    <ListItemText primary={<TextDescricao text={conteudo.titulo} />} />
                    <ListItemText primary={<TextConteudo text={conteudo.descricao} />} />
                </Stack>
            </Stack>
        </ListItem>
    }

    const handleExpanded = () => {
        setExpanded(!expanded);
    }

    return <Accordion expanded={props.show ? props.show : expanded}
        id={atendimento.codigo + '-' + atendimento.tipo + '-r'}
        aria-labelledby={atendimento.codigo + '-' + atendimento.tipo + '-r'}
        style={props.color ? colorSac(atendimento) : { background: 'inherit' }} >
        <AccordionSummary
            id={`panel${atendimento.codigo}a-header-r`}
            aria-controls={`panel${atendimento.codigo}a-content-r`}
            style={{ borderBottom: '1px solid rgb(0 0 0 / 12%)' }}
        >
            <GridCenter>
                <Grid item xs={10} onClick={handleExpanded}>
                    <GridCenter>
                        <Grid item xs={12}>
                            <TitleSmall title={atendimento.pessoa} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SubTitleSmall subTitle={atendimento.assunto} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SubTitleSmall subTitle={atendimento.software} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SubTitleSmall subTitle={formatDateTime(atendimento.data_abertura)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SubTitleSmall subTitle={atendimento.atendpref} />
                        </Grid>
                    </GridCenter>
                </Grid>
                <Grid item xs={1}>
                    <>
                        {props.atender ? permRender('AT',
                            <ButtonToolTip title='Atender'
                                color='green'
                                icon={<LocalPhoneOutlinedIcon />}
                                onClick={() => putSac('atender', { codigo: atendimento.codigo, fila: false })}
                            />) : ''}

                        {props.vincular ? atendimento.vinculado === 0 ?
                            <ButtonToolTip title='Vincular'
                                color='blue'
                                icon={<LinkOutlinedIcon />}
                                onClick={() => sacVincular(codigo, atendimento.codigo, 'post',)}
                            />
                            :
                            <ButtonToolTip title='Desvincular'
                                color='blue'
                                icon={<LinkOffOutlinedIcon />}
                                onClick={() => sacVincular(atendimento.vinculado, atendimento.codigo, 'delete',)}
                            />
                            : ''}
                    </>
                </Grid>
            </GridCenter>
        </AccordionSummary>
        <AccordionDetails>
            <StackJustify>
                <SubTitleSmall subTitle={"ID: " + atendimento.codigo} />
                <SubTitleSmall subTitle={"Contato: " + atendimento.contato} />
                <SubTitleSmall subTitle={"CNPJ: " + formatCnpjCpf(atendimento.cnpjcpf)} />
                {atendimento.versao_copia ? <SubTitleSmall subTitle={"Versão: " + formatCnpjCpf(atendimento.versao_copia)} /> : ''}
            </StackJustify>
            <p />
            <Divider />
            {conteudos && !loadingConteudos ?
                <div style={{ maxHeight: '250px', overflow: 'auto' }}>
                    {conteudos.map(ListConteudos).reverse()}
                </div> : ''
            }
            <Divider />
            <p />
            <GridCenter>
                <Grid item xs={4}>
                    <SubTitle Title='Criado em: ' subTitle={formatDateTime(atendimento.data_criacao)} />
                </Grid>
                {atendimento.data_atendimento && atendimento.status === 'A' ?
                    <Grid item xs={4}>
                        <SubTitle Title='Iniciado em: ' subTitle={formatDateTime(atendimento.data_atendimento)} />
                    </Grid>
                    : ''}
                {atendimento.data_finalizacao && atendimento.status === 'F' ?
                    <Grid item xs={4}>
                        <SubTitle Title='Finalizado em: ' subTitle={formatDateTime(atendimento.data_finalizacao)} />
                    </Grid>
                    : ''}
            </GridCenter>
        </AccordionDetails>
    </ Accordion >
}