import * as React from 'react';
import { Box, Divider, Grid, Stack } from '@mui/material';
import { ObsCliente, SubTitle, TextConteudo, TextDescricao } from '../../typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import { GridCenter, GridLeft } from '../../grid';
import { StackJustify, StackLeft } from '../../stack';
import { MenuConteudo } from '../components/menu';
import { formatCnpjCpf } from '../../../utils/string';
import { formatDateTime } from '../../../utils/date';
import { listaLength } from '../../../utils/string';
import { getLayout } from '../../../utils/theme';
import DialogSac from '../components/dialog/sac';
import DialogMarcadores from '../components/dialog/marcadores';
import DialogOutros from '../components/dialog/outros';
import DialogAnexos from '../components/dialog/anexos';
import DialogRastreio from '../components/dialog/rastreio';
import DialogVincular from '../components/dialog/vincular';
import DialogUltimos from '../components/dialog/ultimos';
import { SacContext } from '../../../context';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { getSetor } from '../../../utils/sac';
import { DescrDialog } from '../../dialog';

export function BodySac() {
    const { sac, acao, codigo } = React.useContext(SacContext);
    const [ocultaConteudo, setOcultaConteudo] = React.useState(sac.tipo !== 'AT' ? true : false);
    const [codObs, setCodObs] = React.useState(0);
    const [showDescr, setShowDescr] = React.useState(false);

    React.useEffect(() => {
        setCodObs(0);
    }, [codigo])

    function ListConteudos(conteudo, c) {

        function listDescr(conteudo, c) {
            return <ListItemText key={c}
                primary={<TextConteudo text={conteudo} />}
            />
        }

        function setOBS(codigo) {
            if (getSetor() !== 'AT')
                if (codigo === codObs)
                    setCodObs(0)
                else
                    setCodObs(codigo)
        }

        return (ocultaConteudo && conteudo.descricao === '' ? '' :
            <ListItem alignItems="flex-start" key={c}
                style={{
                    cursor: 'pointer',
                    border: conteudo.codigo === codObs && getSetor() !== 'AT' ? `1px solid rgb(217 217 217)` : '',
                }}
                onClick={() => setOBS(conteudo.codigo)}>
                <Stack style={{ maxWidth: '-webkit-fill-available' }}>
                    <div style={{ fontSize: '13px', whiteSpace: visualViewport <= 768 ? 'inherit' : 'nowrap' }}>
                        <StackLeft>
                            {conteudo.codigo === codObs ?
                                <ArrowRightOutlinedIcon color='iconColor' /> :
                                <KeyboardArrowRightOutlinedIcon color='iconColor' fontSize='small' style={{ marginTop: '2px' }} />
                            }
                            <TextConteudo text={conteudo.titulo} />
                        </StackLeft>
                    </div>
                    <div style={{ marginLeft: '20px', maxWidth: '100%' }}>{conteudo.descricao.split('\\n').map(listDescr)}</div>
                </Stack>
            </ListItem >
        )
    }

    function ListTelefones(telefone, t) {
        return (telefone.telefone ? <Grid item xs={12} md={2} key={t} >
            <SubTitle subTitle={telefone.telefone} /></Grid>
            : ''
        )
    }

    function ListRastreios(rastreio, r) {
        return <Grid item key={r}>
            <Stack direction='row' alignItems='center'><KeyboardArrowRightOutlinedIcon fontSize='small' color='iconColor' /><SubTitle subTitle={`${rastreio.descricao}`} /></Stack>
        </Grid>
    }

    function InfCliente(atendimento) {
        return (
            <GridCenter>
                <Grid item xs={6}>
                    <SubTitle Title='Contato: ' subTitle={atendimento.contato} />
                </Grid>
                {/* {atendimento.observacao ?
                    <Grid item xs={12} >
                        <ObsCliente Title='Observação: ' subTitle={atendimento.observacao} />
                    </Grid>
                    : ''} */}
                <Grid item xs={12}>
                    <GridLeft>
                        <SubTitle Title='Telefone: ' />{' '}
                        {atendimento.telefones ? atendimento.telefones.map(ListTelefones).reverse() : ''}
                    </GridLeft>
                </Grid>
            </GridCenter>
        )
    }

    function DescAtendimento(atendimento) {
        return (<>
            {atendimento.caminhobd ?
                <Grid item xs={12}>
                    <SubTitle Title='Banco de dados: ' />
                    <TextDescricao Title='Banco de dados: ' text={atendimento.caminhobd} />
                    <Divider style={{ margin: '10px 0px 10px 0px' }} />
                </Grid>
                : ''}

            {atendimento.observacao ?
                <ObsCliente Title='OBSERVAÇÃO: ' subTitle={atendimento.observacao} />
                : ''}
            <StackJustify>
                <SubTitle Title='Descrição' />
                <MenuConteudo
                    onClickOcultarConteudo={() => setOcultaConteudo(!ocultaConteudo)}
                    showDescr={codObs > 0}
                    onClickShowConteudo={() => setShowDescr(true)}
                    ocultaConteudo={ocultaConteudo} atendimento={atendimento}
                />
            </StackJustify>
            {
                atendimento.conteudos ?
                    <List dense
                        sx={{
                            position: 'relative',
                            '& ul': { padding: 0 },
                            maxHeight: getLayout() === 'email' ? '2000px' : '250px',
                            overflow: 'auto'
                        }}
                        subheader={<li />}
                    >
                        {atendimento.conteudos.map(ListConteudos).reverse()}
                    </List>
                    :
                    <SubTitle key={atendimento.codigo + 'cont'} subTitle='Nenhuma descrição encontrada.' />
            }</>
        )
    }

    function InfSac(atendimento) {
        return (<GridLeft>
            <Grid item xs={12} lg={4}>
                <SubTitle Title='ID: ' subTitle={atendimento.codigo} />
            </Grid>
            <Grid item xs={12} lg={4}>
                <SubTitle Title='CNPJ: ' subTitle={formatCnpjCpf(atendimento.cnpjcpf)} />
            </Grid>
            {atendimento.versao_copia ?
                <Grid item xs={12} lg={4}>
                    <SubTitle Title='Versão: ' subTitle={atendimento.versao_copia} />
                </Grid>
                : ''}
            {listaLength(atendimento.rastreios) > 0 ?
                <Grid item xs={12}>
                    <Grid container alignItems='center'>
                        <SubTitle Title='Rastreio ' />
                        {atendimento.rastreios.map(ListRastreios).reverse()}
                    </Grid>
                </Grid>
                : ''}
            {atendimento.tags ? <Grid item xs={6}>
                <SubTitle Title='Tags: ' subTitle={atendimento.tags} />
            </Grid> : ''}
        </GridLeft>
        )
    }

    function InfDatas(atendimento) {
        return (<GridCenter>
            <Grid item xs={4}>
                <SubTitle Title='Criado em: ' subTitle={formatDateTime(atendimento.data_criacao)} />
            </Grid>
            {atendimento.data_atendimento && atendimento.status === 'A' ?
                <Grid item xs={4}>
                    <SubTitle Title='Iniciado em: ' subTitle={formatDateTime(atendimento.data_atendimento)} />
                </Grid>
                : ''}
            {atendimento.data_finalizacao && atendimento.status === 'F' ?
                <Grid item xs={4}>
                    <SubTitle Title='Finalizado em: ' subTitle={formatDateTime(atendimento.data_finalizacao)} />
                </Grid>
                : ''}
        </GridCenter>
        )
    }

    const handleClose = () => {
        setShowDescr(false);
    }

    return (<>
        {
            acao !== 'sac' && acao !== 'Marcadores' &&
                acao !== 'Outros' && acao !== 'Anexos' &&
                acao !== 'Rastreio' && acao !== 'Vincular' &&
                acao !== 'Ultimos' ?
                <DialogSac acao={acao} atendimento={sac} />
                : (acao === 'Marcadores' && codigo === sac.codigo) ?
                    < DialogMarcadores atendimento={sac} />
                    : ((acao === 'Anexos') && codigo === sac.codigo) ?
                        <DialogAnexos codigo={sac.codigo} status={sac.status} />
                        : (acao === 'Outros' && codigo === sac.codigo) ?
                            <DialogOutros codpessoa={sac.codpessoa} />
                            : (acao === 'Rastreio' && codigo === sac.codigo) ?
                                <DialogRastreio codigo={sac.codigo} />
                                : (acao === 'Vincular' && codigo === sac.codigo) ?
                                    <DialogVincular codigo={sac.codigo} />
                                    : (acao === 'Ultimos' && codigo === sac.codigo) ?
                                        <DialogUltimos codpessoa={sac.codpessoa} />
                                        : (sac) ?
                                            (
                                                <Box
                                                    id={sac.codigo + '-' + sac.tipo}
                                                    aria-labelledby={sac.codigo + '-' + sac.tipo}
                                                >
                                                    <Divider style={{ margin: '10px 0px 10px 0px' }} />
                                                    <GridCenter>
                                                        <Grid item xs={12}>
                                                            {InfSac(sac)}
                                                            <p />
                                                            {InfCliente(sac)}
                                                            <p />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {DescAtendimento(sac)}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Divider style={{ margin: '10px 0px 10px 0px' }} />
                                                            {InfDatas(sac)}
                                                            <p />
                                                        </Grid>
                                                    </GridCenter>
                                                    {showDescr ? <DescrDialog idDescr={codObs} open={showDescr} atendimento={sac} onClose={handleClose} /> : ''}
                                                </Box >
                                            )
                                            : ''
        }</>)
}