import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, ButtonGroup, Divider, Grid, Paper, Stack } from '@mui/material';
import { colorSac, expandedGrp } from '../../utils/sac';
import { TitleGrupos, TitleSmall } from '../typography';
import { Add, BookMark, Delete, DownIcon, EditIcon, UpIcon } from '../icons';
import { StackLeft } from '../../components/stack';
import { HeaderSac } from '../sac/header';
import { BodySac } from '../sac/body';
import { GeralContext, SacContext } from '../../context';
import { getTheme } from '../../utils/theme';
import PhoneIcon from '@mui/icons-material/Phone';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { ButtonToolTip } from '../button';


//#region AccordionGrupos ******************************************************************************************************************************************************************************************************
export function AccordionGrupos(props) {
    const [expanded, setExpanded] = React.useState(false);
    const { expandedSubGrp } = React.useContext(GeralContext);

    React.useEffect(() => {
        setExpanded(expandedGrp(props.grupo, props.registros, props.subGrupo, props.expanded, expandedSubGrp))
    }, [props.registros, props.grupo])

    function IconGrp() {
        switch (props.grupo) {
            case 'Meus atendimentos':
                return <PhoneIcon color='iconColor' fontSize='small' />;
            case 'Sendo atendidos':
                return <SupportAgentIcon color='iconColor' fontSize='small' />;
            case 'Retornados':
                return <RotateLeftIcon color='iconColor' fontSize='small' />;
            case 'Aguardando':
                return <HistoryToggleOffIcon color='iconColor' fontSize='small' />;
            case 'Aguardando':
                return <HistoryToggleOffIcon color='iconColor' fontSize='small' />;
            default:
                return <TurnedInNotIcon color='iconColor' fontSize='small' />;
        }
    }

    return (<Paper elevation={0} style={{ background: 'inherit', marginBottom: '5px' }}>
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} disableGutters sx={{ margin: props.subGrupo ? '15px' : '', padding: '0px' }}>
            <AccordionSummary aria-controls={`panel${props.index}Grupo-content`} onClick={() => setExpanded(!expanded)}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ width: '100%' }}
                >
                    <div style={{ display: 'flex', }}>
                        {props.iconGrp ? props.iconGrp : <IconGrp />}
                        <span style={{ marginLeft: '5px' }} />
                        <TitleGrupos title={props.grupo} />
                    </div>
                    <TitleGrupos title={props.registros} />
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '5px', paddingTop: props.subGrupo ? '15px' : '0px', borderBottom: props.subGrupo ? '1px solid #444a55' : '0px' }}>
                {props.children}
            </AccordionDetails>
        </Accordion >
    </Paper>
    );
}
//#endregion AccordionGrupos *******************************************************************************************************************************************************************************************************************

//#region AccordionSubGrupos ******************************************************************************************************************************************************************************************************
export function AccordionSubGrupos(props) {
    const [expanded, setExpanded] = React.useState(false);
    const { expandedSubGrp } = React.useContext(GeralContext);

    React.useEffect(() => {
        setExpanded(expandedGrp(props.grupo, props.registros, expandedSubGrp))
    }, [props.registros, props.grupo, expandedSubGrp])

    return (<Paper style={{ margin: '10px' }} elevation={0}>
        <Accordion expanded={expanded}
            onChange={() => setExpanded(!expanded)}
            style={{ background: getTheme() === 'dark' ? '#343944' : '#f4f5fa' }}
        >
            <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${props.index}Grupo-content`}
                onClick={() => setExpanded(!expanded)}
            >
                <Grid container>
                    <Grid item xs={12} sm={8}>
                        <StackLeft>
                            {props.subGrupo ? <BookMark fontSize='13' /> : ''}<TitleGrupos title={props.grupo} />
                        </StackLeft>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ textAlignLast: 'end' }}>
                        <TitleGrupos title={props.registros !== undefined ? props.registros : ''} />
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0 5px 0px 10px' }} >
                {expanded ? props.children : ''}
            </AccordionDetails>
        </Accordion>
    </Paper >
    );
}
//#endregion AccordionGrupos *******************************************************************************************************************************************************************************************************************

//#region AccordionGruposTC ******************************************************************************************************************************************************************************************************
export function AccordionGruposTC(props) {
    return (<Paper style={{ background: 'inherit', margin: '10px' }}>
        <Accordion expanded={true}
            style={getTheme() === 'dark' ?
                { background: props.subGrupo ? "#343944" : '#262a34' } :
                { background: props.subGrupo ? "#f1f1f1" : '#f1f1f1' }
            }
        >
            <AccordionSummary
                aria-controls={`panel${props.index}Grupo-content`}
            >
                <div style={{ textAlign: 'justify' }}>
                    <TitleGrupos title={props.grupo} />
                    <TitleSmall title={(props.registros + ' registros')} />
                </div>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0 5px 20px 10px' }} >
                <Divider />
                <br />
                {props.children}
            </AccordionDetails>
        </Accordion>
    </Paper >
    );
}
//#endregion AccordionGruposTC *******************************************************************************************************************************************************************************************************************

// //#region AccordionAtendimentos ******************************************************************************************************************************************************************************************************
export function AccordionAtendimentos(props) {
    const atendimento = props.atendimento;
    const { codigo } = React.useContext(SacContext);
    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {
        setExpanded(codigo ? codigo === atendimento.codigo : false);
    }, [codigo])

    return (
        <Box p={0} m={0} mb={1}>
            <Accordion expanded={expanded}
                id={atendimento.codigo + '-' + atendimento.tipo}
                aria-labelledby={atendimento.codigo + '-' + atendimento.tipo}
                style={colorSac(atendimento)}
            >
                <AccordionSummary
                    id={`panel${atendimento.codigo}a-header`}
                    aria-controls={`panel${atendimento.codigo}a-content`}
                >
                    <HeaderSac
                        atendimento={atendimento}
                        codigo={atendimento.codigo}
                        status={atendimento.status}
                        showMenu={atendimento.status !== 'F'}
                        showMenuGeral={atendimento.status !== 'F'}
                    />
                </AccordionSummary>
                {expanded && codigo === atendimento.codigo && props.loading ?
                    <AccordionDetails >
                        <BodySac />
                    </AccordionDetails>
                    : ''}
            </Accordion>
        </Box >
    );
}

export function AccordionRastreios(props) {
    const [expanded, setExpanded] = React.useState(false);
    const item = props.item;

    return (
        <Accordion expanded={expanded} disableGutters sx={{ margin: '15px', padding: '0px', background: '#343944' }}>
            <AccordionSummary aria-controls={`panel${item.codigo}Grupo-content`} sx={{ width: '100%' }} >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={6} onClick={() => setExpanded(!expanded)}>
                        <TitleSmall title={item.descricao} />
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'end' }}>
                        {expanded ?
                            <>
                                <ButtonToolTip title='Novo filho' icon={<Add fontSize='16px' />} onClick={props.onClickNovo} />
                                <ButtonToolTip title='Editar item' icon={<EditIcon fontSize='16px' onClick={props.onClickEditar} />} />
                                <ButtonToolTip title='Excluir item' icon={<Delete fontSize='16px' onClick={props.onClickExcluir} />} />
                            </> : ''}
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                {props.children}
            </AccordionDetails>
        </Accordion>
    )
}


//#region AccordionPastas ******************************************************************************************************************************************************************************************************
export function AccordionPastasConfig(props) {
    const [expanded, setExpanded] = React.useState(false);

    return (
        <Accordion expanded={expanded} id='pastas' name='pastas'
            style={{ background: props.cor }}
            onClick={props.onClick}
        >
            <AccordionSummary
                aria-controls={`panel${props.index}Grupo-content`}
                style={{ height: '5px', marginBottom: expanded ? '-10px' : '' }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="stretch"
                >
                    <Grid item xs={10} onClick={() => setExpanded(!expanded)} onClickCapture={props.onClickCapture}>
                        <StackLeft>
                            <div style={{ paddingTop: '5px' }}>{props.icon}</div>
                            <TitleGrupos title={props.pasta} />
                        </StackLeft>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'end' }}>
                        {expanded && props.pasta.id !== 1 ?
                            <ButtonGroup>
                                <ButtonToolTip title='Adicionar' icon={<Add fontSize='20px' color='iconColor' />} onClick={props.onClickAdd} />
                                <ButtonToolTip title='Editar Pasta' icon={<EditIcon fontSize='20px' color='iconColor' />} onClick={props.onClickEdit} />
                                <ButtonToolTip title='Remover Pasta' icon={<Delete fontSize='20px' color='iconColor' />} onClick={props.onClickRemove} />
                            </ButtonGroup>
                            : ''
                        }
                        {props.ocultarMov || expanded ? '' :
                            <ButtonGroup>
                                <ButtonToolTip title='Mover para cima' icon={<UpIcon fontSize='28px' color='green' />} onClick={props.onClickUp} />
                                <ButtonToolTip title='Mover para baixo' icon={<DownIcon fontSize='28px' color='red' />} onClick={props.onClickDown} />
                            </ButtonGroup>
                        }
                    </Grid>
                </Grid>
                <StackLeft>
                </StackLeft>
            </AccordionSummary>
            <AccordionDetails >
                {expanded ? props.children : ''}
            </AccordionDetails>
        </Accordion>
    );
}
//#endregion AccordionPastas ******************************************************************************************************************************************************************************************************
