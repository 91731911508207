import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '@mui/material';
import { GeralContext } from '../../context';

export default function SnackbarAlert() {
    const [open, setOpen] = React.useState(false);
    const { mensagem } = React.useContext(GeralContext);

    React.useEffect(() => {
        if (mensagem !== undefined) {
            setOpen(true);
        }
    }, [mensagem])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            {mensagem ?
                <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    action={action}
                >
                    {mensagem ? <Alert variant='filled' style={{ color: 'white' }} severity={mensagem.severity}>{mensagem.mensagem}</Alert> : ''}
                </Snackbar>
                : ''}
        </div>
    );
}