import { useContext, useEffect } from 'react';
import { ContainerPages } from '../../components/container';
import { TabSetor } from '../../components/tabs';
import { GeralContext, SacContext } from '../../context';

export default function Sac() {
    const { toggleAtualizar } = useContext(GeralContext);
    const { toggleCodigo } = useContext(SacContext);

    useEffect(() => {
        toggleAtualizar(true);
        toggleCodigo(0);
    }, [])

    return (
        <ContainerPages >
            <TabSetor />
        </ContainerPages>
    );
}
