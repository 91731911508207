import React, { useEffect, useRef, useState } from 'react';
import { TextFieldDefault } from '../../components/textfield';
import { iniFaq, iniFormat, iniRastreios } from '../../utils/constructor';
import { ContainerPages } from '../../components/container';
import useRastreios from '../../hooks/useRastreios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, ButtonGroup, Divider, MenuItem, Paper, Select, Stack } from '@mui/material';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import { getThemeContraste, getThemeContrasteColor, heightDefault } from '../../utils/theme';
import { TextDescricaoLarge, Title } from '../../components/typography';
import { BoldIcon, Check, ItalicIcon, ListIcon, OrderListIcon, StrikethroughIcon, UnderlinedIcon, ImageIcon, LinkOn, IconSave, EditIcon } from '../../components/icons';
import { ButtonConcluido, ButtonToolTip } from '../../components/button';
import useFaq from '../../hooks/useFaq';
import { SelectTipoFaq } from '../../components/select';
import { getSession, setSession } from '../../utils/storage';
import { useParams } from 'react-router-dom';
import { filtrarLista } from '../../utils/geral';
import { listaLength } from '../../utils/string';

function Faq() {
    const editableDivRef = useRef(null);
    const [faq, setFaq] = useState(iniFaq);
    const { postFaq, getFaq, faqEdit, loading } = useFaq();
    const { getRastreios, rastreios } = useRastreios();
    const [format, setFormat] = useState(iniFormat);
    const { codigo } = useParams();

    useEffect(() => {
        getRastreios();
        if (getSession('tempFaq')) {
            setFaq(JSON.parse(getSession('tempFaq')))
            document.getElementById('textFaq').innerHTML = JSON.parse(getSession('tempFaq')).descricao;
        } else {
            if (codigo)
                getFaq(codigo);

            if (faqEdit.codigo > 0) {
                setFaq({
                    "codigo": faqEdit.codigo,
                    "titulo": faqEdit.titulo,
                    "descricao": faqEdit.descricao,
                    "tipo": faqEdit.tipo,
                    "rastreio": faqEdit.rastreio
                })
                document.getElementById('textFaq').innerHTML = faqEdit.descricao;
            }
        }
    }, [faqEdit.codigo])

    const updateStatus = (index, key) => {
        const updatedItems = format.map((item, i) => {
            if (i === index) {
                return { ...item, [key]: !item[key] };
            }
            return item;
        });
        setFormat(updatedItems);
    };

    const toggleStyle = (command) => {
        document.execCommand(command, false, null);
        const index = format.findIndex(item => item.hasOwnProperty(command));
        updateStatus(index, command);
    };

    const fontSizeMap = {
        2: '14px',
        3: '16px',
        4: '18px',
        5: '24px',
        6: '36px',
        7: '48px',
    };

    React.useEffect(() => {
        let item = filtrarLista(rastreios, 'codigo', faq.rastreio);
        if (listaLength(item) > 0) {
            setFaq((prevalue) => {
                return {
                    ...prevalue,
                    rastreio: item[0]
                }
            })
        }
    }, [faq.rastreio])

    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        setFaq((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        })
    }

    const changeFontSize = (event) => {
        const fontSizeValue = event.target.value;
        document.execCommand('fontSize', false, fontSizeValue);
        const fontElements = editableDivRef.current.getElementsByTagName('font');
        for (let i = 0; i < fontElements.length; i++) {
            const fontElement = fontElements[i];
            if (fontElement.size === fontSizeValue) {
                fontElement.removeAttribute('size');
                fontElement.style.fontSize = fontSizeMap[fontSizeValue];
            }
        }
    };

    const insertImage = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                document.execCommand('insertImage', false, e.target.result);

                // Adiciona handles de redimensionamento após a inserção da imagem
                const images = editableDivRef.current.getElementsByTagName('img');
                const lastImage = images[images.length - 1];  // A última imagem inserida
                makeImageResizable(lastImage);
            };
            reader.readAsDataURL(file);
        }
    };

    const makeImageResizable = (img) => {
        img.style.border = '1px solid #ccc';
        img.style.position = 'relative';
        img.style.cursor = 'nwse-resize';

        const handleResize = (e) => {
            const startX = e.clientX;
            const startY = e.clientY;
            const startWidth = img.offsetWidth;
            const startHeight = img.offsetHeight;

            const onMouseMove = (moveEvent) => {
                const newWidth = startWidth + (moveEvent.clientX - startX);
                const newHeight = startHeight + (moveEvent.clientY - startY);
                img.style.width = `${newWidth}px`;
                img.style.height = `${newHeight}px`;
            };

            const onMouseUp = () => {
                window.removeEventListener('mousemove', onMouseMove);
                window.removeEventListener('mouseup', onMouseUp);
            };

            window.addEventListener('mousemove', onMouseMove);
            window.addEventListener('mouseup', onMouseUp);
        };

        img.addEventListener('mousedown', handleResize);
    };

    const makeLink = () => {
        const selection = window.getSelection();
        if (!selection.rangeCount) return;
        const range = selection.getRangeAt(0);
        const selectedText = range.toString();
        if (!selectedText) return;
        const link = document.createElement('a');
        link.href = selectedText;
        link.target = '_blank';
        link.textContent = 'Clique aqui';
        link.style.cursor = 'pointer';
        link.style.color = getThemeContraste();
        link.addEventListener('click', (event) => {
            event.preventDefault();
            window.open(link.href, '_blank');
        });
        range.deleteContents();

        range.insertNode(link);
        selection.removeAllRanges();
    };

    const handleSave = () => {
        sessionStorage.removeItem('tempFaq');
        postFaq({
            "codigo": faq.codigo,
            "titulo": faq.titulo,
            "descricao": document.getElementById('textFaq').innerHTML,
            "tipo": faq.tipo,
            "rastreio": faq.rastreio.codigo
        })
    }

    const handleSaveTemp = () => {
        setSession('tempFaq',
            JSON.stringify({
                "codigo": faq.codigo,
                "titulo": faq.titulo,
                "descricao": document.getElementById('textFaq').innerHTML,
                "tipo": faq.tipo,
                "rastreio": faq.rastreio
            })
        )
    }

    function trilhaRastreio() {
        let listaRastreio = [];
        listaRastreio.push(filtrarLista(rastreios, 'codigo', faq.rastreio.codigo)[0]);
        listaRastreio = listaRastreio.filter(function (element) {
            return element !== undefined;
        });
        for (let i = 0; i < listaLength(listaRastreio); i++) {
            if (listaRastreio[i].master > 0) {
                listaRastreio.push(filtrarLista(rastreios, 'codigo', listaRastreio[i].master)[0]);
            }
        }
        return listaRastreio.reverse();
    }

    function ListarRastreio(r) {
        return <TextDescricaoLarge key={r.codigo} text={r.master === 0 ? r.descricao + ' ' : ' > ' + r.descricao} />
    }

    return (
        <ContainerPages ativarLogo={true} activeAuxBar={true} title={faq.codigo > 0 ? faq.titulo : 'Cadastrar Faq'}>
            <Stack direction='column' spacing={1}>
                <Box component={Paper} elevation={3} p={1}>
                    <Stack direction="row" justifyContent="space-between">
                        <Title text="Faq" />
                        <ButtonGroup variant="outlined" color="primary">
                            <ButtonToolTip text='Salvar' disabled={loading} onClick={() => handleSave()} icon={<IconSave />} />
                            <ButtonToolTip text='Salvar Temporário' disabled={loading} onClick={() => handleSaveTemp()} icon={<EditIcon />} />
                        </ButtonGroup>
                    </Stack>
                    <TextFieldDefault label="Título" name="titulo" value={faq.titulo} onChange={handleChange} />
                    <Stack mt={1} direction='row' spacing={2}>
                        <SelectTipoFaq name="tipo" value={faq.tipo} onChange={handleChange} />
                        <Select name="rastreio" label="Rastreio" value={faq.rastreio.codigo} onChange={handleChange}>
                            {rastreios.map((r, index) =>
                                <MenuItem key={index} value={r.codigo}>{r.descricao}</MenuItem>
                            )}
                        </Select>
                    </Stack>
                </Box>
                <Stack mt={1} component={Paper} p={1} direction="row" spacing={1}>
                    <ButtonToolTip text="Bold" onClick={() => toggleStyle('bold')} icon={<BoldIcon color={format[0].bold ? 'primary' : 'inherit'} />} />
                    <ButtonToolTip text="Italic" onClick={() => toggleStyle('italic')} icon={<ItalicIcon color={format[1].italic ? 'primary' : 'inherit'} />} />
                    <ButtonToolTip text="Underline" onClick={() => toggleStyle('underline')} icon={<UnderlinedIcon color={format[2].underline ? 'primary' : 'inherit'} />} />
                    <ButtonToolTip text="Strikethrough" onClick={() => toggleStyle('strikeThrough')} icon={<StrikethroughIcon color={format[3].strikeThrough ? 'primary' : 'inherit'} />} />
                    <ButtonToolTip text="Lista" onClick={() => toggleStyle('insertUnorderedList')} icon={<ListIcon />} />
                    <ButtonToolTip text="Lista Ordenada" onClick={() => toggleStyle('insertOrderedList')} icon={<OrderListIcon />} />
                    <ButtonToolTip text="Fonte" />
                    <Select onChange={changeFontSize} defaultValue={4}>
                        <MenuItem value={2}>14</MenuItem>
                        <MenuItem value={3}>16</MenuItem>
                        <MenuItem value={4}>18</MenuItem>
                        <MenuItem value={5}>24</MenuItem>
                        <MenuItem value={6}>36</MenuItem>
                        <MenuItem value={7}>48</MenuItem>
                    </Select>
                    <ButtonToolTip text="Image" onClick={() => document.getElementById('imageInput').click()} icon={<ImageIcon />} />
                    <input type="file" id="imageInput" onChange={insertImage} style={{ display: 'none' }} />
                    <ButtonToolTip text="Link" onClick={makeLink} icon={<LinkOn />} />
                </Stack>
                <Box p={1} minHeight={heightDefault()} component={Paper}>
                    <div id="textFaq" contentEditable={true} ref={editableDivRef} style={{ minHeight: '400px', outline: 'none', color: getThemeContrasteColor() }}>
                    </div>
                </Box>
                <Divider />
                <Stack p={1} component={Paper} direction="row">
                    <TreeView
                        aria-label="file system navigator"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                    >
                        {trilhaRastreio().map(r => <TreeItem key={r.codigo} nodeId={r.codigo.toString()} label={ListarRastreio(r)} />)}
                    </TreeView>
                </Stack>
            </Stack>
        </ContainerPages>
    )
}

export default Faq;
