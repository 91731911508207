import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import { Paper, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { getPerm, getUser } from '../../utils/user';
import { Add, IconMenu } from './../icons';
import { verificarRota } from './../../utils/geral';
import { GeralContext } from './../../context';
import { MenuAppBar } from '../menu';
import DrawerRedsis from '../drawer';
import { ButtonExpandirRecolherSubgrupos, ButtonLinkIcon, ButtonPesquisar } from '../button';
import { getSetor } from '../../utils/sac';
import { SearchNavBar } from '../search';

export function NavBar() {
    const [state, setState] = React.useState(false);
    const { toggleDesativarPesquisa, toggleAtualizar, ativarPesquisa, } = React.useContext(GeralContext);

    return (
        <Paper elevation={0}>
            <Toolbar>
                <IconButton
                    aria-label="open drawer"
                    onClick={() => setState(true)}
                    edge="start"
                >
                    <IconMenu />
                </IconButton>
                <Link to={'/sac'} onClick={() => toggleAtualizar(true)}>
                    <Avatar alt="Logo Redsis" src="../../../app-assets/img/logo/logo.png" sx={{ width: 36, height: 36 }} />
                </Link>
                <Box style={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }} />
                {ativarPesquisa && window.innerWidth > 768 ?
                    <SearchNavBar />
                    : ''}
                {(!verificarRota('sac') && !verificarRota('testcomplete')) || verificarRota('create') ? '' : <ButtonPesquisar />}
                {getPerm('CR') && !verificarRota('externo') && verificarRota('sac') ?
                    <ButtonLinkIcon to='/sac/create' title='Novo Atendimento' icon={<Add fontSize='25px' />} onClick={toggleDesativarPesquisa} />
                    : ''}

                {(verificarRota('sac')) && !(verificarRota('create')) && ((getSetor() === 'PG') || getSetor() === 'AN') ? <ButtonExpandirRecolherSubgrupos /> : ''}

                <MenuAppBar />
            </Toolbar>
            <DrawerRedsis onClose={() => setState(false)} toggleDesativarPesquisa={() => console.log('aqui')} state={state} />
        </Paper >
    );
}

export function NavBarSimple() {
    return (
        <Paper elevation={0}>
            <AppBar>
                <Container maxWidth="xxl">
                    <Toolbar disableGutters >
                        <Avatar alt="Logo Redsis" src="../../../app-assets/img/logo/logo.png" sx={{ width: 48, height: 48, marginLeft: '10px' }} />
                    </Toolbar>
                </Container>
            </AppBar>
        </Paper>
    );
}