import { useState, useEffect } from "react";
import { getSession, setSession } from "../utils/storage";
import axios from "../services/api";
import useToken from "./useToken";


const useTipos = () => {
    const { token } = useToken();
    const [listTipos, setListTipos] = useState([]);
    const [loading, setLoading] = useState(true);

    var config = {
        headers: {
            authorization: token
        }
    };

    //#region RETORNA LISTA DE SETORES ******************************************************************************************************************************************************************************************************
    const getListTipos = async () => {
        if (getSession('tipos') === null) {
            try {
                const response = await axios.get('/atendimentos/tipos', config);
                setListTipos(response.data.result);
                setSession('tipos', JSON.stringify(response.data.result));
            } catch (error) {
                console.log(error);
            }

        } else {
            setListTipos(JSON.parse(getSession('tipos')));
        }
        setLoading(false);
    }
    //#endregion *********************************************************************************************************************************************************************************************************************************

    //#region RETORNA NOME DO SETOR ******************************************************************************************************************************************************************************************************
    function getNome(tipo) {
        for (let i = 0; i < listTipos.length; i++) {
            if (listTipos[i].codigo === tipo) {
                return listTipos[i].nome[0].toUpperCase() + listTipos[i].nome.substring(1).toLowerCase();
            }
        }
    }
    //#endregion *********************************************************************************************************************************************************************************************************************************

    useEffect(() => {
        getListTipos();
        // eslint-disable-next-line
    }, [])

    return { listTipos, loading, getNome };
}

export default useTipos;

