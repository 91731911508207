import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getTheme } from '../utils/theme/';
import { useEffect, useState } from 'react';
import { containerTheme, navBarTheme, typographyTheme, drawerTheme, menuItemTheme, textFieldTheme, paperTheme, inputBaseTheme, formControTheme, inputLabel } from './components';

const dark = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#fff',
        },
        secondary: {
            main: '#ff3d00',
            contrastText: '#ff3d00'
        },
        green: {
            main: '#008000',
            contrastText: '#fff'
        },
        red: {
            main: '#ed3237',
            contrastText: 'white',
        },

        white: {
            main: '#fff',
            contrastText: 'white',
        },
        orange: {
            main: '#ffa500',
            contrastText: 'white',
        },
        blue: {
            main: '#27617e',
            contrastText: 'white',
        },
        yellow: {
            main: '#FFD700',
            contrastText: '#fff'
        },
        iconColor: {
            main: '#e8e9eb',
            contrastText: 'white'
        },

        colorGrupo: {
            main: '#e8e9eb',
            contrastText: 'white'

        },
        colorSubGrupo: {
            main: '#e8e9eb',
            contrastText: 'white'
        },

    },
    components: {
        MuiAppBar: navBarTheme(),
        MuiContainer: containerTheme(),
        MuiTypography: typographyTheme(),
        MuiDrawer: drawerTheme(),
        MuiPaper: paperTheme(),
        MuiMenuItem: menuItemTheme(),
        MuiTextField: textFieldTheme(),
        MuiFilledInput: formControTheme(),
    }
});

const light = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#263238',
        },
        secondary: {
            main: '#ff3d00',
            contrastText: '#ff3d00'
        },
        gray: {
            main: '#263238',
            contrastText: '#fff'
        },
        darkGray: {
            main: '#263238',
            contrastText: 'white'
        },
        green: {
            main: '#008000',
            contrastText: '#fff'
        },

        red: {
            main: '#ed3237',
            contrastText: 'white',
        },

        white: {
            main: '#fff',
            contrastText: 'white',
        },
        orange: {
            main: '#ffa500',
            contrastText: 'white',
        },
        blue: {
            main: '#27617e',
            contrastText: 'white',
        },
        yellow: {
            main: '#FFD700',
            contrastText: '#fff'
        },
        iconColor: {
            main: '#263238',
        },
        colorGrupo: {
            main: '#263238'
        },

        colorSubGrupo: {
            main: '#263238'
        },

        colorInput: {
            main: '#263238'
        }
    },
    components: {
        MuiAppBar: navBarTheme(),
        MuiContainer: containerTheme(),
        MuiTypography: typographyTheme(),
        MuiDrawer: drawerTheme(),
        MuiPaper: paperTheme(),
        MuiMenuItem: menuItemTheme(),
        MuiTextField: textFieldTheme(),
        MuiFilledInput: formControTheme(),
    }
});

export default function ThemeRedsis(props) {
    const [tema, setTema] = useState(dark);

    useEffect(() => {
        if (getTheme() === 'dark')
            setTema(dark)
        else
            setTema(light)
    }, [getTheme()]);

    document.body.style.background = tema === light ? '#f4f5fa' : '#343944';

    return (
        <ThemeProvider theme={tema}>
            {props.children}
        </ThemeProvider>
    );
}